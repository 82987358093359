import React, { useState, Fragment, useEffect, useRef } from "react";
import { useStyles } from "./styles";
import {
  Header,
  AnimationHelper,
  OfferCard,
  CountDownTimer
} from "gce-cxinteracts-ui-components-library";
import { connect } from "react-redux";
import plusIcon from "Configurations/Clients/vodafone/Assets/Images/plusButton@2x.png";
import minusIcon from "Configurations/Clients/vodafone/Assets/Images/minusButton@2x.png";
import DisLikeIconBlack from "Configurations/Clients/vodafone/Assets/Images/ThumbsDOWN_black.png";
import LikeIconBlack from "Configurations/Clients/vodafone/Assets/Images/ThumbsUP_black.png";
import LikeIconWhite from "Configurations/Clients/vodafone/Assets/Images/ThumbsUP_white.png";
import loveItIcon from "Configurations/Clients/vodafone/Assets/Images/love-it.png";
import loveItWhiteIcon from "Configurations/Clients/vodafone/Assets/Images/love-it-white.png";
import DisLikeIconWhite from "Configurations/Clients/vodafone/Assets/Images/ThumbsDOWN_white.png";
import { Button, Typography, useTheme } from "@material-ui/core";
import FavouriteOffers, {
  IFavouriteOffers
} from "Models/ReduxModels/FavouriteOffersModel";
import Offers, {
  IOffers,
  ISpinTowWinConfig,
  ImageClassifier,
  Offer,
  RedemptionExpiryModel
} from "Models/ReduxModels/OffersModel";
import Merchants, { IMerchant } from "Models/ReduxModels/Merchants";
import ClaimedOffers, {
  IClaimedOffers,
  ClaimedOffer
} from "Models/ReduxModels/ClaimedOffersModel";
import {
  ClientModel,
  ClientSessionDetails
} from "Models/ReduxModels/ClientDetails";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  claimOfferGraphQLCall,
  gamifiactionEmailGraphQLCall,
  offerRatingGraphqlCall
} from "Services/OfferSerivice";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import {
  getOfferImage,
  isFavoriteOffer,
  addRemoveFavouriteOffers,
  getLatestClaimDetailsByClaimId,
  getOverlayIcon,
  isObjectEmpty,
  getCustomCtaDetails,
  getOfferBadgeDetails,
  isOfferLocked,
  isOfferCodeValid,
  computeActions,
  isOfferShared,
  updateClaimedOffersFromApi,
  checkConvergedClaim,
  getInstantWinImageConfig,
  loadStandardCreativeImage
} from "Utils/offerUtils";
import {
  capitalizeFirstLetterOfString,
  checkChristmasMonth,
  computeTicks,
  getClientName,
  getCurrentTime,
  getImageDetails,
  getValueFromHashMapArray,
  iOSDevice,
  loadEvents,
  manualParameterPass,
  previousPages,
  updateSaveUnsave_OfferCount,
  addExternalSupport
} from "Utils/GeneralUtils";
import PreClaimDialog from "./PreClaimDialog";
import {
  PreClaimDialogTypes,
  IWTypes,
  LuckyDipTypes,
  EmailPopupTypes,
  SpinToWinTypes
} from "Models/Interfaces/PreClaimDialogTypes";
import { OfferType } from "Models/Interfaces/OfferType";
import InstantWinJourney from "./InstantWinJourney";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import UserDetails, { IUserDetails } from "Models/ReduxModels/UserDetails";
import DisplayCategories, {
  IDisplayCategory,
  DisplayCategoryIds
} from "Models/ReduxModels/DisplayCategories";
import RecommendedOffers from "./RecommendedOffers";
import SortedClaimedIds, {
  ClaimIds
} from "Models/ReduxModels/SortedClaimedIds";
import SortedFavouriteOfferIds, {
  OfferIds
} from "Models/ReduxModels/SortedFavouriteOfferIds";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Actions } from "Models/Interfaces/Actions";
import { showSnackBar } from "Utils/SnackBarUtils";
import { spring } from "react-motion";
import ApplicationFlag, {
  IApplication
} from "Models/ReduxModels/ApplicationFlag";
import moment from "moment";
import { ImagesMapping } from "Configurations/ImagesMapping";
import LuckyDipJourney from "./LuckyDipJourney";
import CMSImages, {
  ICMSImagesDetails,
  ICMSLuckyDeepImageConfigurations
} from "Models/ReduxModels/CMSImages";
import AppConfig from "Models/ReduxModels/AppConfig";
import SpinToWin from "Routes/SpinToWin/SpinToWin";

interface IProps {
  offers: IOffers;
  favorites: IFavouriteOffers;
  merchants: IMerchant;
  claimedOffers: IClaimedOffers;
  clientData: ClientSessionDetails;
  userData: IUserDetails;
  match: any;
  displayCategories: IDisplayCategory;
  displayCategoryIds: number[];
  sortedClaimedIds: ClaimIds;
  favouriteOfferIds: OfferIds;
  applicatinFlags: IApplication;
  CMSimages: ICMSImagesDetails;
  iw_error_message: string | undefined;
  spinTowinConfig: ISpinTowWinConfig;
}

enum Action {
  ENTER = "Enter",
  ENTERED = "Entered",
  CLAIM_OFFER = " Claim Offer",
  SHOW_CODE = "Show Code",
  GET_CODE = "Get Code",
  GET_NEW_CODE = "Get New Code",
  PLAY = "Play",
  SHOW_PRIZE = "Show prize",
  SHOW_OR_GIFT_CODE = "Show or Gift Code",
  VIEW_OFFER = "View Offer",
  VIEW_WEBSITE = "View Website",
  ENTER_NOW = "Enter now",
  ENTER_WEBSITE = "Enter site",
  GAMIFICATION_CLAIM_PRIZE = "Claim prize",
  SPIN = "SPIN"
}

const OfferDetails: React.FC<IProps> = (props: IProps) => {
  let classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const {
    offers,
    merchants,
    favorites,
    claimedOffers,
    clientData,
    userData,
    displayCategories,
    displayCategoryIds,
    sortedClaimedIds,
    favouriteOfferIds,
    applicatinFlags,
    CMSimages,
    iw_error_message,
    spinTowinConfig
  } = props;

  let categories: any = [];
  const theme = useTheme();
  let sortedFavouriteOfferIds: number[] = favouriteOfferIds?.offerIds;
  (displayCategoryIds || []).forEach((id: number, index: number) => {
    categories.push(displayCategories[id]?.offerIds);
  });
  let accessToken = clientData?.token;
  const { id } = props.match.params;
  let offer = offers && offers[id];

  const [expand, collapse] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const clientName = getClientName();
  const [dialogType, setDialogType] = useState<PreClaimDialogTypes>(
    PreClaimDialogTypes.LOADER
  );
  const [showIWJourney, setShowIWJourney] = useState(false);
  const [showSTWJourney, setShowSTWJourney] = useState(false);
  const [stwJourneyType, setStwJourneyType] = useState<SpinToWinTypes>(
    SpinToWinTypes.INTRO
  );
  const [iwJourneyType, setIWJourneyType] = useState<IWTypes>(IWTypes.INTRO);
  const [showEnterAnime, setShowEnterAnime] = useState(false);
  const [showLeaveAnime, setShowLeaveAnime] = useState(false);
  const [showCard, setCardVisibility] = useState(true);
  const [showSavedOfferAnimation, setSavedOfferAnimation] = useState(-1);
  const [showLDJourney, setShowLDJourney] = useState(false);
  const [ldJourneyType, setLDJourneyType] = useState<LuckyDipTypes>(
    LuckyDipTypes.INTRO
  );
  const [likeDislike, setLikeDisLike] = useState(false);
  const [offerRating, setOfferRating] = useState(4);
  const [selectedFeedbackOption, setSelectedFeedbackOption] = useState(0);
  const [showFeedbackButtons, setShowFeedbackButtons] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [message, setMessage] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [prizeName, setPrizeName] = useState("");
  const [showEmailPopup, setShowEmailPopup] = useState("NONE");
  const [update, setUpdate] = useState(false);
  const [stwWinnerModal, setSTWWinnerModal] = useState<boolean>(false);
  const [stwEmailCapture, setSTWEmailCapture] = useState<boolean>(false);

  let isLocked;
  let isValidCode;
  let offerAction;
  let isShared;
  let offerClaimed: ClaimedOffer | null;

  if (offer) {
    let claimedId =
      location?.state?.isFromMyCode && location.state?.claimedId
        ? location?.state?.claimedId
        : 0;
    isLocked = isOfferLocked(offer);
    isValidCode = isOfferCodeValid(offer);
    offerAction = computeActions(offer, claimedId, location);
    isShared = isOfferShared(offer);
    let claimIds;
    if (
      offers?.[id]?.offerType === OfferType.SPIN_TO_WIN &&
      location?.state?.isFromMyCode
    )
      claimIds = offer?.claimedIds?.filter(
        item => item === location?.state?.claimedId
      );
    else claimIds = offer?.claimedIds;
    offerClaimed =
      getLatestClaimDetailsByClaimId(claimIds, claimedOffers) || null;
  }
  let convergedClaimedStyle = {
    Image: {
      backgroundImage:
        "linear-gradient(to right, rgba(255,21,4,0.65), rgba(255,21,7,0.75)), url('" +
        getOfferImage(ImageClassifier.OFFER_IMAGE_SMALL, offer?.images).url +
        "')"
    }
  };

  if (!offer) {
    history.push("/home");
  }

  const isFromMyCode = location?.state?.isFromMyCode;
  const isInstantWin = offers?.[id]?.offerType === OfferType.INSTANT_WIN;
  const isArticleOffer = offers?.[id]?.offerType === OfferType.ARTICLE;
  const isPrizeDrawOffer = offers?.[id]?.offerType === OfferType.PRIZEDRAW;
  const isRewardsOffer = offers?.[id]?.offerType === OfferType.REWARD;
  const isLuckyDip = offers?.[id]?.offerType === OfferType.LUCKY_DIP;
  const isVisitToWin = offers?.[id]?.offerType === OfferType.VISIT_TO_WIN;
  const isSpinToWin = offers?.[id]?.offerType === OfferType.SPIN_TO_WIN;
  var isIWClaimIP = false;
  let isFavoriteCallIP = false;
  let isClaimIP = false;
  let customCtaDetails = getCustomCtaDetails(offer, "custom-cta-copy");
  let articleCtaDetails = getCustomCtaDetails(offer, "articles-cta-url");
  let prizeDrawCtaDetails = getCustomCtaDetails(offer, "prizedraw-cta-url");
  let redemptionGuidlines = offer?.redemptionDetails?.[0]?.guidelines;
  let emailCapture;

  if (isLuckyDip || isSpinToWin) {
    let claimedData =
      getLatestClaimDetailsByClaimId(offer?.claimedIds, claimedOffers) || null;
    let checkGamificationStatus = claimedData
      ? getValueFromHashMapArray(claimedData?.attributes, "gamificationStatus")
      : null;

    if (
      checkGamificationStatus === "SHOW_POPUP" ||
      checkGamificationStatus === "DETAILS_SUBMITTED" ||
      checkGamificationStatus === "EXPIRED"
    ) {
      emailCapture = true;
    } else {
      emailCapture = offer?.redemptionDetails?.[0]?.captureEmail || false;
    }
  } else {
    emailCapture = offer?.redemptionDetails?.[0]?.captureEmail || false;
  }

  const isPrizeDrawOfferUrl = () => {
    let urlRegex = ConstantMapping[clientName].PRIZE_DRAW_URL_REGEX;
    if (prizeDrawCtaDetails && prizeDrawCtaDetails.value) {
      if (new RegExp(urlRegex).test(prizeDrawCtaDetails?.value)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  let [showInformationStrip, setshowInformationStrip] = useState(
    (isPrizeDrawOffer && !isPrizeDrawOfferUrl() && isValidCode) ||
      (isValidCode && isShared)
  );
  let iwWinImage = new Image();
  let iwLooseImage = new Image();
  let iwIntroImage = new Image();
  let iwPopBalloonImage = new Image();
  let CMS_iwWinImage;
  let CMS_iwLooseImage;
  let CMS_iwIntroImage;
  let CMS_iwIntroText = "";
  let CMS_iwWinnerText = "";
  let CMS_iwLoserText = "";
  let std_intro_text = "";
  let std_winner_text = "";
  let std_loser_text = "";

  //PreLoad large GIFs total 23MB, only for IW offers
  if (isInstantWin) {
    const configKeys = ConstantMapping?.["vodafone"]?.IMAGES_DERIVE_FROM_CMS;
    const textConfigKeys =
      ConstantMapping?.["vodafone"]?.IWTEXT_DRIVEN_FROM_CMS;
    CMS_iwWinImage =
      getInstantWinImageConfig(CMSimages, id, configKeys.iwWinImage) || "";
    CMS_iwLooseImage =
      getInstantWinImageConfig(CMSimages, id, configKeys.iwLooseImage) || "";
    CMS_iwIntroImage =
      getInstantWinImageConfig(CMSimages, id, configKeys.iwIntroImage) || "";

    CMS_iwIntroText =
      getInstantWinImageConfig(CMSimages, id, textConfigKeys.iw_intro_text) ||
      "";
    CMS_iwWinnerText =
      getInstantWinImageConfig(CMSimages, id, textConfigKeys.iw_winner_text) ||
      "";
    CMS_iwLoserText =
      getInstantWinImageConfig(CMSimages, id, textConfigKeys.iw_loser_text) ||
      "";

    if (
      checkChristmasMonth(
        ConstantMapping[clientName].XMAS_IW_START_DATE,
        ConstantMapping[clientName].XMAS_IW_END_DATE
      )
    ) {
      iwIntroImage.src = ImagesMapping[clientName].iwXmasIntroBg;
      iwPopBalloonImage.src = ImagesMapping[clientName].iwXmasIntroSnowflakes;
      iwWinImage.src = ImagesMapping[clientName].iwXmasWinGif;
      iwLooseImage.src = ImagesMapping[clientName].iwXmasLooseGif;
    } else {
      iwIntroImage.src = loadStandardCreativeImage(configKeys.iwIntroImage)
        ? loadStandardCreativeImage(configKeys.iwIntroImage)
        : ImagesMapping[clientName].iwIntroNew;
      iwWinImage.src = loadStandardCreativeImage(configKeys.iwWinImage)
        ? loadStandardCreativeImage(configKeys.iwWinImage)
        : ImagesMapping[clientName].iwWinNew;
      iwLooseImage.src = loadStandardCreativeImage(configKeys.iwLooseImage)
        ? loadStandardCreativeImage(configKeys.iwLooseImage)
        : ImagesMapping[clientName].iwLoseNew;
      iwPopBalloonImage.src = ImagesMapping[clientName].iwPopBalloonGif;
      std_intro_text =
        loadStandardCreativeImage(textConfigKeys.iw_intro_text) || "";
      std_winner_text =
        loadStandardCreativeImage(textConfigKeys.iw_winner_text) || "";
      std_loser_text =
        loadStandardCreativeImage(textConfigKeys.iw_loser_text) || "";
    }
  }

  let ldIntroScreenImage = new Image();
  let ldClawIntroScreenImage = new Image();
  let ldConveyorbeltIntroScreenImage = new Image();
  let ldPickToWinBgImage = new Image();
  let ldCongratsImage = new Image();
  let ldStaticPrizeInterimImage = new Image();
  let LdNewIntroSolo = new Image();
  //CMS Lucy Deep Images
  let CMS_lucky_dip_intro_background;
  let CMS_lucky_dip_intro_animation;
  let CMS_lucky_dip_play_animation;
  let CMS_lucky_dip_congrats_animation;
  //PreLoad large GIFs total <size>, only for LuckyDip offer
  if (isLuckyDip) {
    const CMS_LuckyDeepImageConfigurations =
      CMSimages?.CMS_LuckyDeepImageConfigurations;
    if (CMS_LuckyDeepImageConfigurations) {
      CMS_lucky_dip_intro_background =
        CMS_LuckyDeepImageConfigurations.lucky_dip_intro_background_IMAGEURL ||
        "";
      CMS_lucky_dip_intro_animation =
        CMS_LuckyDeepImageConfigurations.lucky_dip_intro_animation_IMAGEURL ||
        "";
      CMS_lucky_dip_play_animation =
        CMS_LuckyDeepImageConfigurations.lucky_dip_play_animation_IMAGEURL ||
        "";
      CMS_lucky_dip_congrats_animation =
        CMS_LuckyDeepImageConfigurations.lucky_dip_congrats_animation_IMAGEURL ||
        "";
    }

    if (
      checkChristmasMonth(
        ConstantMapping[clientName].XMAS_IW_START_DATE,
        ConstantMapping[clientName].XMAS_IW_END_DATE
      )
    ) {
      LdNewIntroSolo.src = ImagesMapping[clientName].luckydip_New_Intro_solo;
      ldIntroScreenImage.src = ImagesMapping[clientName].iwluckydip_IntroScreen;
      ldClawIntroScreenImage.src = ImagesMapping[clientName].claw_IntroScreen;
      ldConveyorbeltIntroScreenImage.src =
        ImagesMapping[clientName].conveyorbelt_IntroScreen;
      ldPickToWinBgImage.src = ImagesMapping[clientName].iwPicktoWin_background;
      ldCongratsImage.src = ImagesMapping[clientName].iwluckydip_CongratsScreen;
      ldStaticPrizeInterimImage.src =
        ImagesMapping[clientName].iwPicktoWin_InterimStatic;
    } else {
      //Attach Images
      LdNewIntroSolo.src = ImagesMapping[clientName].luckydip_New_Intro_solo;
      ldIntroScreenImage.src = ImagesMapping[clientName].luckydip_IntroScreen;
      ldClawIntroScreenImage.src = ImagesMapping[clientName].claw_IntroScreen;
      ldConveyorbeltIntroScreenImage.src =
        ImagesMapping[clientName].conveyorbelt_IntroScreen;
      ldPickToWinBgImage.src = ImagesMapping[clientName].PicktoWin_background;
      ldCongratsImage.src = ImagesMapping[clientName].luckydip_CongratsScreen;
      ldStaticPrizeInterimImage.src =
        ImagesMapping[clientName].picktoWin_InterimStatic;
    }
  }

  //PreLoad large GIFs total <size>, only for SpinToWin offer
  let CMS_spin_to_win_background_img = new Image();
  let CMS_spin_to_win_foreground_img = CMSimages?.CMS_spinToWinConfigurations
    ?.spin_to_win_foreground_img
    ? new Image()
    : null;
  let CMS_spin_to_win_winner_img = new Image();
  let spinToWinSegements;
  let spinToWinColors;
  if (isSpinToWin) {
    CMS_spin_to_win_background_img.src =
      CMSimages?.CMS_spinToWinConfigurations?.spin_to_win_background_img || "";
    if (CMS_spin_to_win_foreground_img)
      CMS_spin_to_win_foreground_img.src =
        CMSimages?.CMS_spinToWinConfigurations?.spin_to_win_foreground_img ||
        "";
    CMS_spin_to_win_winner_img.src =
      CMSimages?.CMS_spinToWinConfigurations?.spin_to_win_winner_screen_bg ||
      "";
    spinToWinSegements = offers?.[id]?.offerPrizeDetails?.map(element => {
      return element.prizeName;
    });
    spinToWinColors = spinTowinConfig?.["wheel-color-scheme"] || [];
  }

  const root = document.getElementById("root");
  // check vmrLite user status
  const { offerEntries, appAccessLevel } = userData;

  let vmrLiteUserStatus =
    appAccessLevel === "PAYG_PLUS_LITE" || appAccessLevel === "PAYG_LITE"
      ? offer.giveToLiteUser
      : true;

  const cardTypes = {
    VMRLite: "VMRLite"
  };

  let onLoadParams = {};

  const isGamificationOffer = () => {
    let instantWinResult = getValueFromHashMapArray(
      offerClaimed?.attributes,
      "InstantWin"
    );
    let gamificationEmailPopup = getValueFromHashMapArray(
      offerClaimed?.attributes,
      "gamificationStatus"
    );
    if (
      ((instantWinResult === "win" && isInstantWin) ||
        isLuckyDip ||
        isSpinToWin) &&
      isValidCode &&
      emailCapture === true &&
      gamificationEmailPopup
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    isIWClaimIP = false;
    setShowEnterAnime(true);
    resetScroll();
    const offerDescription = document.getElementById("offer-description");
    addExternalSupport(offerDescription);
    const keyTerms = document.getElementById("key-terms");
    addExternalSupport(keyTerms);
    const entryMechanics = document.getElementById("entry-mechanics");
    addExternalSupport(entryMechanics);
  }, []);

  useEffect(() => {
    if (offer?.offerRating !== null) {
      let offerRatedValue: any = offer?.offerRating?.offerRating;
      let offerFeedBack = offer?.offerRating?.feedback;
      if (offerRatedValue?.toString() === "0" || "1" || "-1") {
        setLikeDisLike(true);
        setOfferRating(offerRatedValue);
        setShowFeedbackButtons(true);
      }

      if (offerFeedBack !== null) {
        if (
          offerFeedBack ===
            ConstantMapping[clientName].OFFER_LIKED_OPTION_ONE ||
          offerFeedBack ===
            ConstantMapping[clientName].OFFER_DISLIKED_OPTION_ONE
        ) {
          setSelectedFeedbackOption(1);
        } else if (
          offerFeedBack ===
            ConstantMapping[clientName].OFFER_LIKED_OPTION_TWO ||
          offerFeedBack ===
            ConstantMapping[clientName].OFFER_DISLIKED_OPTION_TWO
        ) {
          setSelectedFeedbackOption(2);
        } else if (
          offerFeedBack ===
            ConstantMapping[clientName].OFFER_LIKED_OPTION_THREE ||
          offerFeedBack ===
            ConstantMapping[clientName].OFFER_DISLIKED_OPTION_THREE
        ) {
          setSelectedFeedbackOption(3);
        }
      }
    }
  }, [offer]);

  useEffect(() => {
    resetScroll();
    setShowLeaveAnime(false);
    setShowEnterAnime(true);
    logEvents({ eventType: EVENT_TYPE.VIEW_OFFER, offerId: offer?.offerId });
    if (checkCardType(offer?.addType)) {
      logEvents({
        eventType: EVENT_TYPE.SHOW_VMR_LITE_BANNER_OFFER,
        offerId: offer?.offerId
      });
    }
    pushAllEventsToServer();

    // Gamification commented
    // let instantWinResult  = getValueFromHashMapArray(offerClaimed?.attributes,"InstantWin");
    // if( ((instantWinResult === "win" && isInstantWin) || isLuckyDip) && isValidCode && emailCapture === true){
    //   setshowInformationStrip(true)
    // }
    // Uncomment Below Gamification Code -
    // let gamificationEmailPopup= getValueFromHashMapArray(offerClaimed?.attributes,"gamificationStatus")
    // if(gamificationEmailPopup && (instantWinResult === "win" || isLuckyDip ) && isValidCode && emailCapture === true){
    //   setShowEmailPopup(gamificationEmailPopup)
    // }

    let gamificationEmailPopup = getValueFromHashMapArray(
      offerClaimed?.attributes,
      "gamificationStatus"
    );
    if (
      gamificationEmailPopup &&
      isGamificationOffer() &&
      ((isSpinToWin && isFromMyCode) || !isSpinToWin)
    ) {
      setshowInformationStrip(true);
      setShowEmailPopup(gamificationEmailPopup);
    }
  }, [offer]);

  const toggle = () => {
    const state = {
      onLoadParams: {
        show_view: false,
        event_name: "PageInteraction",
        event_action: "ui element",
        event_label: "All Terms and Conditions",
        page_subsection: "offers",
        page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${offer?.title}`,
        same_page_event: true
      }
    };
    loadEvents(previousPages, { state });
    collapse(!expand);
  };

  const checkGamificationOffer = IW_LD_Offer => {
    if (IW_LD_Offer && isValidCode && emailCapture) {
      return true;
    }
    return false;
  };

  // Gamification Email Capture;
  const gamificationInformationStrip = () => {
    let gamificationHeading = { heading: "", content: "" };
    // uncommomment when actual api returns
    let checkEmailPopup = getValueFromHashMapArray(
      offerClaimed?.attributes,
      "gamificationStatus"
    );

    if (isGamificationOffer()) {
      if (checkEmailPopup === "DETAILS_SUBMITTED") {
        gamificationHeading = {
          heading: "Thank you!",
          content:
            "Your prize will be sent to you using the email address you've provided." ||
            ""
        };
      }

      if (checkEmailPopup === "EXPIRED") {
        gamificationHeading = {
          heading:
            "We needed your email address within 24 hours of gameplay to be able to issue your prize.",
          content: "Keep an eye out for more VeryMe Rewards prize draws."
        };
      }
    }
    return gamificationHeading;
  };

  const resetScroll = () => {
    if (root) {
      root.scrollTop = 0;
    }
  };

  const renderConvertedText = (info: any, fontSize: string) => {
    return (
      <div
        className={classes.richText}
        dangerouslySetInnerHTML={{ __html: info }}
        style={{ fontSize: fontSize }}
      />
    );
  };

  const navigateCodeRedemption = () => {
    let claimIds;
    if (
      offers?.[id]?.offerType === OfferType.SPIN_TO_WIN &&
      location?.state?.isFromMyCode
    )
      claimIds = offer?.claimedIds?.filter(
        item => item === location?.state?.claimedId
      );
    else claimIds = offer?.claimedIds;
    let claimDetails: ClaimedOffer | null = getLatestClaimDetailsByClaimId(
      claimIds,
      claimedOffers
    );
    if (claimDetails) {
      let page_name: string;
      if (isPrizeDrawOffer) {
        page_name = `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${ConstantMapping[clientName].TEALIUM_PAGE_PRIZE_DRAW}`;
      } else {
        page_name = `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${ConstantMapping[clientName].TEALIUM_PAGE_CLAIM}`;
      }
      onLoadParams = { ...onLoadParams, page_name, page_subsection: "offers" };
      history.push(`/code/${claimDetails.claimedId}/`, { onLoadParams });
    }
  };

  const showInstantWinResult = () => {
    if (isGamificationOffer() && showEmailPopup === "SHOW_POPUP") {
      if (offer?.offerType === OfferType.INSTANT_WIN) {
        setShowIWJourney(true);
        setIWJourneyType(IWTypes.WIN);
      }
      if (offer?.offerType === OfferType.LUCKY_DIP) {
        setShowLDJourney(true);
        setLDJourneyType(LuckyDipTypes.WIN);
      }
      if (offer?.offerType === OfferType.SPIN_TO_WIN) {
        setShowSTWJourney(true);
        setStwJourneyType(SpinToWinTypes.WIN);
      }
    } else {
      navigateCodeRedemption();
    }
  };

  const handleClaimClick = () => {
    switch (offerAction) {
      case Actions.USE:
      case "instant-win":
        showInstantWinResult();
        break;
      case Actions.CLAIM:
      case Actions.CLAIM_AGAIN:
      case Actions.PRIZE_DRAW:
      case Actions.ENTER_PRIZE_DRAW:
      case Actions.VISIT_TO_WIN:
        if (isInstantWin) {
          setIWJourneyType(IWTypes.INTRO);
          setShowIWJourney(true);
        } else {
          if (isLuckyDip) {
            setLDJourneyType(LuckyDipTypes.INTRO);
            setShowLDJourney(true);
          } else if (
            !offer.showPreClaimPopup ||
            offer?.redemptionExpiryModel === RedemptionExpiryModel.None
          ) {
            if (!isClaimIP) {
              isClaimIP = true;
              claimOffer(id)
                .then(() => {
                  isClaimIP = false;
                })
                .catch(() => {
                  isClaimIP = false;
                });
            }
          } else if (
            offer?.redemptionExpiryModel == RedemptionExpiryModel.Timer
          ) {
            setDialogType(PreClaimDialogTypes.TIMER);
            handleClickOpenDialog();
          } else {
            if (offer?.offerType === OfferType.PRIZEDRAW) {
              setDialogType(PreClaimDialogTypes.PRIZEDRAW);
              logEvents({
                eventType: EVENT_TYPE.PRIZE_DRAW_ENTERED,
                offerId: offer?.offerId
              });
            } else {
              if (offer?.shareable) {
                setDialogType(PreClaimDialogTypes.SHAREABLE);
              } else {
                setDialogType(PreClaimDialogTypes.OTHER);
              }
            }
            handleClickOpenDialog();
          }
          break;
        }
        break;
      case Actions.SPIN_TO_WIN:
        setShowSTWJourney(true);
        break;
    }
  };

  const preClaimSuccessAction = () => {
    // console.log("Clicked..")
    // setIWJourneyType(IWTypes.LOOSE); //commnet this using for Mock only
    if (!isIWClaimIP) {
      isIWClaimIP = true;
      claimOffer(id)
        .then(() => {
          isIWClaimIP = false;
        })
        .catch(() => {
          isIWClaimIP = false;
        });
    }
  };

  const getDetailsBadge = () => {
    const offer: Offer | undefined = offers?.[id] || undefined;
    if (
      !offer ||
      (offer?.offerType === OfferType.SPIN_TO_WIN && isFromMyCode)
    ) {
      return undefined;
    }
    if (!(isValidCode && offer.shareable)) {
      if (!isShared) {
        return getOfferBadgeDetails(
          offer,
          "large",
          offerClaimed?.claimedId,
          showEmailPopup
        );
      }
    }
    return undefined;
  };

  const reFetchConditions = async () => {
    try {
      const result = await updateClaimedOffersFromApi(
        offers,
        offer,
        claimedOffers,
        offerClaimed
      );
      if (result) {
        setUpdate(result);
      }
    } catch (error: any) {
      if (error?.networkError) {
        history.push("/error-500");
      } else {
        showSnackBar();
      }
    }
  };

  const claimOffer = async (id: any) => {
    logEvents({
      eventType: EVENT_TYPE.CONFIRM_CLAIM_OFFER,
      offerId: offer?.offerId
    });
    try {
      if (!isInstantWin || !isLuckyDip) {
        setDialogType(PreClaimDialogTypes.LOADER);
        handleClickOpenDialog();
      }
      let res = await claimOfferGraphQLCall(id);
      let claimedOffer = res.data?.claimOffer;
      let sortedclaimedIds: number[] = sortedClaimedIds?.claimIds;
      if (claimedOffer) {
        let currentClaimedOffer: any = {
          claimedId: claimedOffer?.id,
          offerId: claimedOffer?.offerId,
          attributes: claimedOffer?.attributes,
          byAgent: claimedOffer?.byAgent,
          claimDateTime: new Date(claimedOffer?.claimDateTime),
          expiryDateTime: claimedOffer?.expiryDateTime
            ? new Date(claimedOffer?.expiryDateTime)
            : undefined,
          offerType: claimedOffer?.offerType,
          redemptionCode: claimedOffer?.redemptionCode,
          prizeName: claimedOffer?.prizeName,
          prizeType: claimedOffer?.prizeType
        };
        sortedclaimedIds.splice(0, 0, claimedOffer.id);
        let newClaimedOffers = claimedOffers || {};
        newClaimedOffers[currentClaimedOffer.claimedId] = currentClaimedOffer;

        let updatedOffers = offers;
        if (updatedOffers[id].claimedIds?.length) {
          updatedOffers[id].claimedIds.push(claimedOffer.id);
        } else {
          updatedOffers[id].claimedIds = [claimedOffer.id];
        }
        let isNoExpiry = !currentClaimedOffer.expiryDateTime;
        let isFutureExpiry =
          claimedOffer.expiryDateTime &&
          moment(claimedOffer.expiryDateTime).isAfter(getCurrentTime());
        if (
          isFutureExpiry ||
          (claimedOffer.offerType === OfferType[OfferType.PRIZEDRAW] &&
            typeof currentClaimedOffer.claimDateTime == "object") ||
          isNoExpiry
        ) {
          isValidCode = true;
        }

        let result = null;
        if (isInstantWin) {
          result = getValueFromHashMapArray(
            claimedOffer?.attributes,
            "InstantWin"
          );
          updatedOffers[id].instantWinResult = result;
        }
        // Uncomment below code once api start showing instantwin email popup
        let gamificationEmailPopup = getValueFromHashMapArray(
          claimedOffer?.attributes,
          "gamificationStatus"
        );
        updatedOffers[id].gamificationEmailPopup = gamificationEmailPopup;

        let favOfferClaimIds: number[] = (sortedFavouriteOfferIds || []).filter(
          (offerId: number) => {
            return offerId === claimedOffer?.offerId;
          }
        );
        let favOfferClaimId: number | undefined = favOfferClaimIds.length
          ? favOfferClaimIds[0]
          : undefined;
        let index: number | undefined =
          sortedFavouriteOfferIds &&
          favOfferClaimId &&
          sortedFavouriteOfferIds.indexOf(favOfferClaimId);
        if (typeof index === "number") {
          sortedFavouriteOfferIds?.splice(index, 1);
        }
        favOfferClaimId && delete favorites[favOfferClaimId];

        let Entries = currentClaimedOffer?.attributes;
        let totalEntry = 0;
        for (let visit in Entries) {
          if (Entries[visit].offerEntries) {
            totalEntry = Entries[visit].offerEntries;
          }
        }

        new SortedFavouriteOfferIds({
          id: "",
          offerIds: sortedFavouriteOfferIds
        }).$save();
        new FavouriteOffers({
          id: "",
          ...favorites
        }).$save();
        new Offers({
          id: "",
          ...updatedOffers
        }).$save();
        new ClaimedOffers({
          id: "",
          ...newClaimedOffers
        }).$save();
        new SortedClaimedIds({
          id: "",
          claimIds: sortedclaimedIds
        }).$save();

        new UserDetails({
          id: "",
          ...userData,
          offerEntries: totalEntry,
          totalClaimedOffers: userData?.totalClaimedOffers + 1
        }).$save();
        if (!isLuckyDip) handleCloseDialog();
        if (!((isInstantWin && result === "win") || isLuckyDip)) {
          reFetchConditions();
        }
        if (isInstantWin) {
          setIWJourneyType(result === "win" ? IWTypes.WIN : IWTypes.LOOSE);
          setShowEmailPopup(gamificationEmailPopup);
        } else if (isLuckyDip) {
          setTimeout(() => {
            handleCloseDialog();
            setLDJourneyType(LuckyDipTypes.WIN);
            setPrizeName(claimedOffer?.prizeName);
            setShowEmailPopup(gamificationEmailPopup);
          }, 35);
        } else {
          let page_name: string;
          if (isPrizeDrawOffer) {
            page_name = `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${ConstantMapping[clientName].TEALIUM_PAGE_PRIZE_DRAW}`;
          } else {
            page_name = `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${ConstantMapping[clientName].TEALIUM_PAGE_CLAIM}`;
          }
          onLoadParams = {
            ...onLoadParams,
            page_name,
            page_subsection: "offers"
          };
          if (isPrizeDrawOffer && !isPrizeDrawOfferUrl()) {
            history.push(`/code/${currentClaimedOffer?.claimedId}/`, {
              onLoadParams
            });
          } else if (!isPrizeDrawOffer && !isPrizeDrawOfferUrl()) {
            history.push(`/code/${currentClaimedOffer?.claimedId}/`, {
              onLoadParams
            });
          }
        }
      } else {
        throw "Response not valid!";
      }
    } catch (error: any) {
      //Error in claim api
      reFetchConditions();
      handleCloseDialog();
      setShowIWJourney(false);
      setShowLDJourney(false);
      if (error?.networkError) {
        history.push("/error-500");
      } else {
        // Converged offer - error message
        if (offer?.isConvergedOffer) {
          showSnackBar(
            "This converged Offer is already claimed by someone from your family."
          );
        } else {
          showSnackBar();
        }
      }
    }
  };

  const isArticleOfferUrl = () => {
    let urlRegex = ConstantMapping[clientName].ARTICLE_URL_REGEX;
    if (articleCtaDetails && articleCtaDetails.value) {
      if (new RegExp(urlRegex).test(articleCtaDetails?.value)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const isOfferId = () => {
    if (articleCtaDetails && articleCtaDetails.value) {
      if (!isNaN(articleCtaDetails?.value)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const isPageRedirect = () => {
    if (articleCtaDetails && articleCtaDetails.value) {
      if (!isNaN(articleCtaDetails?.value)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const onButtonClick = (value: any, prizedrawValue: any) => {
    if (isArticleOfferUrl() || isPrizeDrawOfferUrl()) {
      let linkElement = document.createElement("a");
      // need to prefix external to ensure that url opens in a browser and not the webview window
      if (value) {
        linkElement.setAttribute("href", `external:${value}`);
      } else if (prizedrawValue) {
        linkElement.setAttribute("href", `external:${prizedrawValue}`);
        handleClaimClick();
      }

      linkElement.setAttribute("target", "_system");
      // linkElement.setAttribute("rel", "");
      linkElement.click();
    } else if (isNaN(value)) {
      switch (value) {
        case "referafriend":
          history.push("/referfriend");
          break;
      }
    } else if (isOfferId()) {
      if (!offers[+value]) {
        history.push("/error-500");
        return;
      }
      history.push(`/offer-details/${value}`, { value });
      onOfferClick();
    } else {
      return handleClaimClick();
    }
  };

  const getButtonText = (articleCta: string | null) => {
    let action: any = null;
    let prizeType = location?.state?.prizeType;

    // let isWin =
    //   getValueFromHashMapArray(offerClaimed?.attributes, "InstantWin") ===
    //   "win";

    //check offerType and set the button text accordingly
    if (
      isGamificationOffer() &&
      ((isSpinToWin && isFromMyCode) || !isSpinToWin)
    ) {
      action = Action.GAMIFICATION_CLAIM_PRIZE;
      return action;
    }
    if (
      (offer?.offerType === OfferType.INSTANT_WIN ||
        offer?.offerType === OfferType.LUCKY_DIP ||
        (offer?.offerType === OfferType.SPIN_TO_WIN && !isFromMyCode)) &&
      (offerAction === Actions.CLAIM || offerAction === Actions.LOCKED)
    ) {
      if (customCtaDetails?.value.replace(/\s/g, "").length)
        action = customCtaDetails?.value;
      else action = Action.PLAY;
    } else if (
      offer?.offerType === OfferType.SPIN_TO_WIN &&
      isFromMyCode &&
      prizeType === ConstantMapping[getClientName()].GRAND_PRIZE &&
      offerAction === Actions.USE
    ) {
      action = Action.SHOW_PRIZE;
    } else if (
      offer?.offerType === OfferType.INSTANT_WIN &&
      offerAction === "instant-win"
    ) {
      action = Action.SHOW_PRIZE;
    } else if (offer?.offerType === OfferType.LUCKY_DIP) {
      if (
        offerAction === Actions.CLAIM ||
        offerAction === Actions.CLAIM_AGAIN
      ) {
        action = Action.PLAY;
      } else {
        action = Action.SHOW_PRIZE;
      }
    } else if (offerAction === Actions.USE) {
      if (offer?.shareable) {
        action = Action.SHOW_OR_GIFT_CODE;
      } else {
        action = Action.SHOW_CODE;
      }
    } else if (offerAction === Actions.VISIT_TO_WIN_ENTERED) {
      action = Action.ENTERED;
    } else if (
      offerAction === Actions.PRIZE_DRAW_ENTERED &&
      isPrizeDrawOfferUrl()
    ) {
      action = Action.ENTER_WEBSITE;
    } else if (customCtaDetails?.value.replace(/\s/g, "").length) {
      action = customCtaDetails?.value;
    } else if (articleCta) {
      if (isArticleOfferUrl()) {
        action = Action.VIEW_WEBSITE;
      } else {
        action = Action.VIEW_OFFER;
      }
    } else {
      switch (offerAction) {
        case Actions.CLAIM:
          action = capitalizeFirstLetterOfString(Action.GET_CODE);
          break;
        case Actions.CLAIM_AGAIN:
          action = Action.GET_NEW_CODE;
          break;
        case Actions.ENTER_PRIZE_DRAW:
        case Actions.PRIZE_DRAW:
          action = Action.ENTER;
          break;
        case Actions.VISIT_TO_WIN:
          action = Action.ENTER_NOW;
          break;
        case Actions.LOCKED:
          if (isPrizeDrawOffer) {
            action = Action.ENTER;
            break;
          }
          if (isVisitToWin) {
            action = Action.ENTER_NOW;
            break;
          }
          action = capitalizeFirstLetterOfString(Action.GET_CODE);
          break;
      }
    }
    return action;
  };

  const getArticleValue = () => {
    let articleValue: any = null;
    if (
      !isObjectEmpty(articleCtaDetails) &&
      articleCtaDetails &&
      articleCtaDetails?.value
    ) {
      articleValue = articleCtaDetails?.value;
    } else {
      articleValue = null;
    }
    return articleValue;
  };

  const getPrizeDrawValue = () => {
    let prizeDrawValue: any = null;
    if (
      !isObjectEmpty(prizeDrawCtaDetails) &&
      prizeDrawCtaDetails &&
      prizeDrawCtaDetails?.value
    ) {
      prizeDrawValue = prizeDrawCtaDetails?.value;
    } else {
      prizeDrawValue = null;
    }
    return prizeDrawValue;
  };

  const getCTAStyle = action => {
    let style: CSSProperties = {};
    switch (action) {
      case Actions.USE:
      case Actions.LUCKY_DRAW_WIN:
      case "instant-win":
        style = {
          backgroundColor: "#00b0ca",
          boxShadow: theme.shadows[5]
        };
        break;
      case Actions.CLAIM:
      case Actions.CLAIM_AGAIN:
      case Actions.ENTER_PRIZE_DRAW:
      case Actions.PRIZE_DRAW:
      case Actions.VISIT_TO_WIN:
      case Actions.SPIN_TO_WIN:
        style = {
          backgroundColor: theme.palette.primary.main,
          boxShadow: theme.shadows[4]
        };
        break;
      case Actions.LOCKED:
        style = {
          backgroundColor: "#aaa",
          boxShadow: theme.shadows[1]
        };
        break;
      case Actions.PRIZE_DRAW_ENTERED:
        style = {
          backgroundColor: "#00B0CA",
          boxShadow: theme.shadows[1]
        };
        break;
      case Actions.NOP:
      case Actions.UNAVAILABLE:
      case Actions.VISIT_TO_WIN_ENTERED:
        return style;
    }
    return style;
  };

  const renderButton = () => {
    let showOffersAction = false;
    let showArticleAction = false;
    let showPrizeDrawAction = false;
    let articleCtaValue = getArticleValue();
    let prizeDrawCtaValue = getPrizeDrawValue();
    // If it is converged reward offer and already claimed, then hide the CTA Button
    if (checkConvergedClaim(offer)) {
      return undefined;
    }

    if (offerAction === Actions.PRIZE_DRAW_ENTERED && isPrizeDrawOfferUrl()) {
      showPrizeDrawAction = true;
    }

    if (
      offerAction !== Actions.PRIZE_DRAW_ENTERED &&
      offerAction !== Actions.VISIT_TO_WIN_ENTERED &&
      offer?.instantWinResult !== "lose" &&
      !isArticleOffer
    ) {
      showOffersAction = true;
    }

    if (isArticleOffer && articleCtaValue) {
      showArticleAction = true;
    }
    if (
      (!showOffersAction && !showArticleAction && !showPrizeDrawAction) ||
      offerAction === "shared"
    ) {
      return undefined;
    } else if (
      isGamificationOffer() &&
      (showEmailPopup === "DETAILS_SUBMITTED" ||
        showEmailPopup === "EXPIRED") &&
      offer?.offerType !== OfferType.SPIN_TO_WIN
    ) {
      return undefined;
    } else if (
      isGamificationOffer() &&
      (showEmailPopup === "DETAILS_SUBMITTED" ||
        showEmailPopup === "EXPIRED") &&
      offer?.offerType === OfferType.SPIN_TO_WIN &&
      isFromMyCode
    ) {
      return undefined;
    } else {
      return (
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            className={classes.redButton}
            style={getCTAStyle(offerAction)}
            onClick={e => {
              e.stopPropagation();
              onButtonClick(articleCtaValue, prizeDrawCtaValue);
            }}
          >
            {getButtonText(articleCtaValue)}
          </Button>
        </div>
      );
    }
  };

  const renderTermsContent = () => {
    let termsAndConditions = offer?.termsAndConditions;
    if (offer?.termsAndConditionsUrl) {
      return (
        <>
          {renderConvertedText(termsAndConditions, "14px")}
          <p>
            {ConstantMapping[clientName].TERMS_AND_CONDITIONS_URL_TEXT}
            <a
              href={offer?.termsAndConditionsUrl}
              className={classes.termsAndConditionsUrl}
            >
              here
            </a>
          </p>
        </>
      );
    } else {
      return renderConvertedText(termsAndConditions, "14px");
    }
  };

  const eventTriggerForAnchorTag = () => {
    const termAndCondition = document.getElementById("termsCondition");
    addExternalSupport(termAndCondition);
    const anchor = termAndCondition?.getElementsByTagName("a") || [];
    if (anchor?.length > 0) {
      for (const button of anchor as any) {
        button.addEventListener("click", function (event) {
          const state = {
            onLoadParams: {
              show_view: false,
              event_name: "PageInteraction",
              event_action: "link",
              event_label: "See full terms and conditions online",
              page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${offer?.title}`,
              same_page_event: true
            }
          };
          loadEvents(previousPages, { state });
        });
      }
    }
  };

  const renderExpandedContent = () => {
    setTimeout(() => {
      eventTriggerForAnchorTag();
    }, 10);

    return <div className={classes.tcText}>{renderTermsContent()}</div>;
  };

  const renderTermsAndConditions = () => {
    return (
      <div id="termsCondition">
        <h2 className={classes.heading}>
          <div className={classes.tcWrapper}>
            <div>{ConstantMapping[clientName].TERMS_AND_CONDITIONS_TEXT}</div>
            <button
              type="button"
              onClick={toggle}
              tabIndex={0}
              className={classes.buttonDefault}
              aria-label="terms and conditions"
            >
              <img
                src={expand ? minusIcon : plusIcon}
                alt={ConstantMapping[clientName].EXPAND_TERMS_ALT_TEXT}
                className={expand ? classes.minusIcon : classes.plusIcon}
              />
            </button>
          </div>
        </h2>
        {expand ? renderExpandedContent() : ""}
      </div>
    );
  };

  //for new component

  const ratingID = null;

  const handleFeedbackSelection = async (feedbackText, buttonIndex) => {
    setSelectedFeedbackOption(buttonIndex);

    setFeedback(feedbackText);
    let rating = offer?.offerRating;
    let uOffers = offers;

    try {
      const resp = await offerRatingGraphqlCall(
        rating?.id,
        userData?.userId,
        rating?.offerRating,
        offer?.offerId,
        userData?.externalRegistrationId,
        feedbackText
      );
      if (resp.data) {
        showSnackBar("Reward Rating submitted successfully.");
        let data = resp?.data?.saveOfferRatingDetails;
        let rating: any = {
          id: data.id,
          customerId: data.customerId,
          offerId: data.offerId,
          offerRating: data.offerRating,
          feedback: data.feedback
        };

        if (uOffers[id].offerRating) {
          uOffers[id].offerRating = rating;
        }
        new Offers({
          id: "",
          ...uOffers
        }).$save();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const likeDislikeRating = async (e, str) => {
    e.preventDefault();
    setLikeDisLike(true);
    let offerRating: any;
    offerRating =
      str === "like" ? 1 : str === "dislike" ? -1 : str === "love" ? 0 : null;
    setOfferRating(offerRating);
    let uOffers = offers;

    if (offerRating !== (4 || null)) {
      setShowFeedbackButtons(true);
    }

    try {
      const resp = await offerRatingGraphqlCall(
        ratingID,
        userData?.userId,
        offerRating,
        offer?.offerId,
        userData?.externalRegistrationId,
        feedback
      );
      if (resp.data) {
        showSnackBar("Reward Rating submitted successfully.");
        let data = resp?.data?.saveOfferRatingDetails;
        let rating: any = {
          id: data.id,
          customerId: data.customerId,
          offerId: data.offerId,
          offerRating: data.offerRating,
          feedback: data.feedback
        };
        if (uOffers[id]) {
          uOffers[id].offerRating = rating;
        }
        new Offers({
          id: "",
          ...uOffers
        }).$save();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rateOffer = offerData => {
    let isOfferLiked = offerRating === 1 ? true : false;
    let isOfferDisliked = offerRating === -1 ? true : false;
    let isOfferLoved = offerRating === 0 ? true : false;
    let offerFeedBack = offerData?.offerRating?.feedback;

    return (
      <div className={classes.divLike}>
        <h6 className={classes.textRate}>
          {likeDislike
            ? ConstantMapping[clientName].OFFER_RATED_HEADING
            : ConstantMapping[clientName].OFFER_RATE_HEADING}
        </h6>
        <div className={classes.offerRateText}>
          {likeDislike
            ? ConstantMapping[clientName].OFFER_RATED_DESCRIPTION
            : ConstantMapping[clientName].OFFER_RATE_DESCRIPTION}
        </div>
        <div className={classes.ratingbuttonContainerTop}>
          {/* I LOVE IT BUTTON */}
          <button
            className={classes.likeButtonContainer}
            style={{
              color: isOfferLoved ? "#fff" : "#000",
              background: isOfferLoved ? theme.palette.primary.main : "#fff",
              border: isOfferLoved ? "none" : "1px solid darkgrey"
            }}
            onClick={e => {
              likeDislikeRating(e, "love");
            }}
            disabled={offerRating !== 4 || likeDislike}
          >
            <img
              className={classes.thumbIconStyle}
              alt="Reward Like Image"
              src={isOfferLoved ? loveItWhiteIcon : loveItIcon}
            />
            <h4 className={classes.ratingButtonTextStyle}>I love it!</h4>
          </button>
          {/* "I like it" button */}
          <button
            className={classes.likeButtonContainer}
            style={{
              color: isOfferLiked ? "#fff" : "#000",
              background: isOfferLiked ? theme.palette.primary.main : "#fff",
              border: isOfferLiked ? "none" : "1px solid darkgrey"
            }}
            onClick={e => {
              likeDislikeRating(e, "like");
            }}
            disabled={offerRating !== 4 || likeDislike}
          >
            <img
              className={classes.thumbIconStyle}
              alt="I Like It Image"
              src={isOfferLiked ? LikeIconWhite : LikeIconBlack}
            />
            <h4 className={classes.ratingButtonTextStyle}>I like it</h4>
          </button>
        </div>
        {/* "Not for me" button */}
        <div className={classes.ratingbuttonContainerBottom}>
          <button
            className={classes.dislikeButtonContainer}
            style={{
              color: isOfferDisliked ? "#fff" : "#000",
              background: isOfferDisliked ? theme.palette.primary.main : "#fff",
              border: isOfferDisliked ? "none" : "1px solid darkgrey"
            }}
            onClick={e => {
              likeDislikeRating(e, "dislike");
            }}
            disabled={offerRating !== 4 || likeDislike}
          >
            <img
              className={classes.thumbIconStyle}
              alt="Reward DisLike Image"
              src={isOfferDisliked ? DisLikeIconWhite : DisLikeIconBlack}
            />
            <h4 className={classes.ratingButtonTextStyle}>Not for me</h4>
          </button>
        </div>
        {showFeedbackButtons && (
          <div className={classes.responseButtonContainer}>
            <div
              className={classes.offerRateText}
              style={{
                marginBottom: "10px",
                color: theme.palette.primary.main
              }}
            >
              {offerRating === 0 || offerRating === 1
                ? ConstantMapping[clientName].OFFER_LIKED_OPTIONS_HEADING
                : ConstantMapping[clientName].OFFER_DISLIKED_OPTIONS_HEADING}
            </div>
            <button
              style={{
                color: selectedFeedbackOption === 1 ? "#fff" : "#000",
                background:
                  selectedFeedbackOption === 1
                    ? theme.palette.primary.main
                    : "#fff",
                border:
                  selectedFeedbackOption === 1 ? "none" : "1px solid darkgrey"
              }}
              className={classes.responseButton}
              disabled={offerFeedBack !== null}
              onClick={() =>
                handleFeedbackSelection(
                  offerRating === 0 || offerRating === 1
                    ? ConstantMapping[clientName].OFFER_LIKED_OPTION_ONE
                    : ConstantMapping[clientName].OFFER_DISLIKED_OPTION_ONE,
                  1
                )
              }
            >
              {offerRating === 0 || offerRating === 1
                ? ConstantMapping[clientName].OFFER_LIKED_OPTION_ONE
                : ConstantMapping[clientName].OFFER_DISLIKED_OPTION_ONE}
            </button>
            <button
              style={{
                color: selectedFeedbackOption === 2 ? "#fff" : "#000",
                background:
                  selectedFeedbackOption === 2
                    ? theme.palette.primary.main
                    : "#fff",
                border:
                  selectedFeedbackOption === 2 ? "none" : "1px solid darkgrey"
              }}
              className={classes.responseButton}
              disabled={offerFeedBack !== null}
              onClick={() =>
                handleFeedbackSelection(
                  offerRating === 0 || offerRating === 1
                    ? ConstantMapping[clientName].OFFER_LIKED_OPTION_TWO
                    : ConstantMapping[clientName].OFFER_DISLIKED_OPTION_TWO,
                  2
                )
              }
            >
              {offerRating === 0 || offerRating === 1
                ? ConstantMapping[clientName].OFFER_LIKED_OPTION_TWO
                : ConstantMapping[clientName].OFFER_DISLIKED_OPTION_TWO}
            </button>
            <button
              style={{
                color: selectedFeedbackOption === 3 ? "#fff" : "#000",
                background:
                  selectedFeedbackOption === 3
                    ? theme.palette.primary.main
                    : "#fff",
                border:
                  selectedFeedbackOption === 3 ? "none" : "1px solid darkgrey"
              }}
              className={classes.responseButton}
              disabled={offerFeedBack !== null}
              onClick={() =>
                handleFeedbackSelection(
                  offerRating === 0 || offerRating === 1
                    ? ConstantMapping[clientName].OFFER_LIKED_OPTION_THREE
                    : ConstantMapping[clientName].OFFER_DISLIKED_OPTION_THREE,
                  3
                )
              }
            >
              {offerRating === 0 || offerRating === 1
                ? ConstantMapping[clientName].OFFER_LIKED_OPTION_THREE
                : ConstantMapping[clientName].OFFER_DISLIKED_OPTION_THREE}
            </button>
          </div>
        )}
      </div>
    );
  };

  const renderKeyTerms = () => {
    return (
      <div className={classes.keyTermsWrapper}>
        {renderConvertedText(offer?.keyTerms, "18px")}
      </div>
    );
  };

  const renderKeyTermsSection = () => {
    return (
      <div className={classes.keyTermsContainer} id="key-terms">
        <h2 className={classes.heading}>
          {ConstantMapping[clientName].KEY_TERMS_HEADING_TEXT}
        </h2>
        {renderKeyTerms()}
      </div>
    );
  };

  const renderEntryMechanicsTerms = () => {
    return (
      <div className={classes.keyTermsWrapper}>
        {renderConvertedText(offer?.entryMechanics, "18px")}
      </div>
    );
  };

  const renderEntryMechanicsSection = () => {
    return (
      <div className={classes.keyTermsContainer} id="entry-mechanics">
        <h2 className={classes.heading}>
          {ConstantMapping[clientName].ENTRY_MECHANICS_HEADING_TEXT}
        </h2>
        {renderEntryMechanicsTerms()}
      </div>
    );
  };

  const handleExternalLinkClick = () => {
    let url = `external:${ConstantMapping[clientName].VMRLite_TopUp_URL}`;
    logEvents({
      eventType: EVENT_TYPE.CONFIRM_VMR_LITE_TOP_UP_BUTTON,
      offerId: offer.offerId
    });
    window.open(`${url}`, "_blank");
  };

  const renderRestrictedOfferSection = () => {
    return (
      <div
        className={`${classes.keyTermsContainer} ${classes.restrictedTermContainer}`}
      >
        <div className={classes.restrictedSpacing}>
          <h2 className={classes.heading} style={{ opacity: 0.3 }}>
            {ConstantMapping[clientName].KEY_TERMS_HEADING_TEXT}
          </h2>
          <div>
            <h3 className={classes.restrictedHeading}>
              {ConstantMapping[clientName].VMRLite_RESTRICTED_OFFER_HEADING}
            </h3>
            <p className={classes.restrictedText}>
              {appAccessLevel === "PAYG_PLUS_LITE"
                ? ConstantMapping[clientName].PAYGPLUS_RESTRICTED_OFFER_TEXT_ONE
                : ConstantMapping[clientName].VMRLite_RESTRICTED_OFFER_TEXT_ONE}
            </p>
            <p className={classes.restrictedText}>
              {ConstantMapping[clientName].VMRLite_RESTRICTED_OFFER_TEXT_TWO}
            </p>
            <div
              className={classes.buttonWrapper}
              style={{ justifyContent: "center" }}
            >
              <Button
                variant="contained"
                className={`${classes.redButton} ${classes.restrictedRedBtn}`}
                style={getCTAStyle(offerAction)}
                onClick={handleExternalLinkClick}
              >
                {appAccessLevel === "PAYG_PLUS_LITE"
                  ? ConstantMapping[clientName].PAYGPLUS_UPGRADE_BUTTON
                  : ConstantMapping[clientName].VMRLite_TopUp_Button}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDescription = () => {
    let description = offer?.description;
    return (
      <div
        className={`${classes.descriptionText} ${
          isVisitToWin ? classes.additionalDescription : ""
        }`}
        id="offer-description"
      >
        {renderConvertedText(description, "18px")}
      </div>
    );
  };

  const renderAdditionalDescription = () => {
    let getAddDescription = offer?.additionalDetails;
    let additionalDescriptionValue = "";
    for (let keys in getAddDescription) {
      if ("additionalDescription" === offer?.additionalDetails[keys].key) {
        additionalDescriptionValue = offer?.additionalDetails[keys].value;
      }
    }
    return (
      <div className={`${classes.descriptionText}`}>
        {renderConvertedText(additionalDescriptionValue, "18px")}
      </div>
    );
  };

  const getInfoStripDetails = () => {
    let prizeDrawStripDetails = {
      heading: "",
      content: ""
    };

    if (showEmailPopup === "SHOW_POPUP") {
      return undefined;
    }

    if (isValidCode) {
      if (isPrizeDrawOffer) {
        prizeDrawStripDetails = {
          heading: ConstantMapping[clientName].PRIZE_DRAW_ENTERED_HEADING,
          content: redemptionGuidlines || ""
        };
      } else if (isInstantWin || isLuckyDip) {
        prizeDrawStripDetails = gamificationInformationStrip();
      } else if (isShared) {
        prizeDrawStripDetails = {
          heading: "",
          content: ConstantMapping[clientName].OFFER_SHARED_TEXT
        };
      }
    }
    return prizeDrawStripDetails;
  };

  const showTimer = () => {
    if (
      isValidCode &&
      offer.offerType !== OfferType.PRIZEDRAW &&
      !isShared &&
      offer.offerType !== OfferType.VISIT_TO_WIN &&
      offerClaimed?.prizeType !== ConstantMapping[getClientName()].GRAND_PRIZE
    ) {
      if (offer?.claimedIds.length) {
        if (isGamificationOffer()) {
          return false;
        }
        if (offer.offerType === OfferType.INSTANT_WIN) {
          if (
            offer.instantWinResult === "win" &&
            offerClaimed?.expiryDateTime
          ) {
            return true;
          } else {
            return false;
          }
        } else if (
          offerClaimed?.expiryDateTime &&
          isSpinToWin &&
          isFromMyCode
        ) {
          return true;
        } else {
          return false;
        }
      } else return false;
    }
    return false;
  };

  const gamificationStrip = (offer: Offer) => {
    let claimedOffer = offerClaimed;
    let stripText;
    let detailsAdded = false;
    const imageDetails = getOfferImage(
      ImageClassifier.OFFER_IMAGE_LARGE,
      offer?.images
    );
    let result: any = getValueFromHashMapArray(
      claimedOffer?.attributes,
      "InstantWin"
    );
    let gamificationShowEmailPopup = getValueFromHashMapArray(
      offerClaimed?.attributes,
      "gamificationStatus"
    );

    // let gamificationShowEmailPopup:any = EmailPopupTypes.SHOW_POPUP
    if (
      ((isInstantWin && result === "win") || isLuckyDip || isSpinToWin) &&
      emailCapture === true &&
      isValidCode
    ) {
      if (
        gamificationShowEmailPopup === "SHOW_POPUP" ||
        gamificationShowEmailPopup === "DETAILS_SUBMITTED"
      ) {
        stripText = "Congratulations winner!";
        detailsAdded = true;
      }
      if (gamificationShowEmailPopup === "EXPIRED") {
        stripText = "Sorry you've missed out";
        detailsAdded = false;
      }
    }

    return (
      <div
        aria-label={imageDetails?.altText}
        className={classes.sharedBackgroundImage}
        style={{ backgroundImage: `url(${imageDetails?.url})` }}
      >
        <Typography
          className={classes.overlayStripText}
          variant={"h6"}
          style={{
            backgroundColor: `${
              detailsAdded ? "rgb(230, 0, 0)" : "rgb(51, 51, 51)"
            }`
          }}
        >
          {stripText}
        </Typography>
      </div>
    );
  };

  const showOverlayStrip = (offer: Offer) => {
    let claimedOffer = offerClaimed;
    const imageDetails = getOfferImage(
      ImageClassifier.OFFER_IMAGE_LARGE,
      offer?.images
    );
    let result: any = getValueFromHashMapArray(
      claimedOffer?.attributes,
      "InstantWin"
    );
    let stripText =
      result === "win"
        ? ConstantMapping[clientName].STRIP_INFO_IW_WIN
        : ConstantMapping[clientName].STRIP_INFO_IW_LOOSE;

    return (
      <div
        aria-label={imageDetails?.altText}
        className={classes.sharedBackgroundImage}
        style={{ backgroundImage: `url(${imageDetails?.url})` }}
      >
        <Typography
          className={classes.overlayStripText}
          variant={"h6"}
          style={{
            backgroundColor:
              result === "win"
                ? "rgba(230, 0, 0, 0.85)"
                : "rgba(68, 68, 68, 0.9)"
          }}
        >
          {stripText}
        </Typography>
      </div>
    );
  };

  const computeTicks = (date: Date | undefined) => {
    if (!date) return 0;
    let ticks = new Date(date).getTime();
    return ticks;
  };

  const checkCardType = addType => {
    if (addType === cardTypes.VMRLite) {
      return true;
    }
  };

  const reviewSectionRef = useRef<HTMLDivElement | null>(null);

  const handleScrollToReviewSection = () => {
    if (reviewSectionRef.current) {
      reviewSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  let checkSpecificBanner = "999999998" || "999999997";

  const renderCard = () => {
    let offerId = offer?.offerId;
    let addType = offer?.addType;
    return (
      <div style={{ position: "relative" }} id="cardContainer">
        <OfferCard
          mode="full"
          imageDetails={getOfferImage(
            ImageClassifier.OFFER_IMAGE_LARGE,
            offer?.images
          )}
          logoDetails={getImageDetails(
            merchants[offer?.merchantId]?.imageUrl,
            ""
          )}
          showFavorites={
            !(
              (isFromMyCode && isSpinToWin) ||
              offer?.offerType === OfferType.PRIZEDRAW ||
              (isValidCode && isShared) ||
              checkCardType(addType)
            )
          }
          title={offer?.title || ""}
          onActionClicked={() => {
            if (!isFavoriteCallIP && !offer?.isStoreGiftingOffer) {
              isFavoriteCallIP = true;
              addRemoveFavouriteOffers(
                offer?.offerId,
                favorites,
                favouriteOfferIds
              )
                .then((data: any) => {
                  data?.add && setSavedOfferAnimation(offerId);
                  setTimeout(() => {
                    showSavedOfferAnimation === offerId &&
                      setSavedOfferAnimation(-1);
                  }, 800);
                  isFavoriteCallIP = false;
                  let event_name;
                  if (data?.add) {
                    event_name = ConstantMapping[clientName].TEALIUM_OFFER_SAVE;
                  } else {
                    event_name =
                      ConstantMapping[clientName].TEALIUM_OFFER_UNSAVE;
                  }
                  if (updateSaveUnsave_OfferCount() < 3) {
                    manualParameterPass(previousPages, {
                      show_view: false,
                      event_name: event_name,
                      page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${offer?.title}`,
                      same_page_event: true,
                      veryme_offer_name: offer?.title
                    });
                  }
                })
                .catch(() => {
                  isFavoriteCallIP = false;
                });
            }
          }}
          badge={
            emailCapture === true && (isInstantWin || isLuckyDip) && isValidCode
              ? undefined
              : showInformationStrip ||
                showTimer() ||
                (isValidCode && offer?.instantWinResult === "lose")
              ? undefined
              : getDetailsBadge()
          }
          isFavorite={isFavoriteOffer(offerId)}
          showSavedAnimation={showSavedOfferAnimation === offerId}
          options={{
            cardActionAltText: {
              saveAltText: ConstantMapping[clientName].SAVE_ALT_TEXT,
              unsaveAltText: ConstantMapping[clientName].UNSAVE_ALT_TEXT
            },
            overlayIcon: checkConvergedClaim(offer)
              ? checkConvergedClaim(offer)
              : isLocked && !isValidCode
              ? getOverlayIcon(offer)
              : undefined,
            overrideComponent: {
              CTAComponent: () => (
                <div className={classes.rateOfferButtonOnCard}>
                  <Button
                    className={classes.rateOfferCardButton}
                    onClick={handleScrollToReviewSection}
                  >
                    Rate this reward
                  </Button>
                </div>
              ),

              ...(showTimer()
                ? {
                    ContentComponent: () => (
                      <CountDownTimer
                        headerInfoText="Code expires in:"
                        remainingTicks={computeTicks(
                          offerClaimed?.expiryDateTime
                        )}
                        style={{
                          background: "#000000bf",
                          color: "#fff",
                          height: "74px",
                          padding: "9px 5vw",
                          boxSizing: "border-box",
                          zIndex: 3,
                          width: "100%",
                          borderBottom: "3px solid rgb(230, 0, 0)",
                          fontSize: "12px"
                        }}
                        styleOptions={{
                          headerInfoStyle: {},
                          timerNumbersStyle: {
                            fontFamily: "VodafoneRgBold"
                          }
                        }}
                        options={{
                          onComplete: delta => {
                            //this function is called when timer counter is complete
                            //signature - {"total":0,"days":0,"hours":0,"minutes":0,"seconds":0,"milliseconds":0,"completed":true}
                            if (delta?.completed) {
                              reFetchConditions();
                            }
                          }
                        }}
                      ></CountDownTimer>
                    )
                  }
                : isValidCode && offer?.instantWinResult === "lose"
                ? { OfferImage: () => showOverlayStrip(offer) }
                : isGamificationOffer() &&
                  ((isSpinToWin && isFromMyCode) || !isSpinToWin)
                ? { OfferImage: () => gamificationStrip(offer) }
                : undefined)
            },

            actionElementStyle: offer?.isStoreGiftingOffer
              ? {
                  fillColor: "#cccccc",
                  strokeColor: "#cccccc"
                }
              : undefined,
            overrideStyle: checkConvergedClaim(offer) && convergedClaimedStyle
          }}
          isBlurred={
            checkConvergedClaim(offer) ? true : isLocked && !isValidCode
          }
          informationStripDetails={
            showInformationStrip && isValidCode && !showTimer()
              ? getInfoStripDetails()
              : undefined
          }
          showAnimation={true}
          animationProps={{
            showEnterAnimation: showEnterAnime,
            showExitAnimation: showLeaveAnime
          }}
        ></OfferCard>
      </div>
    );
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const onEmailSubmitCallBack = async data => {
    const { fName, sName, email } = data;
    if (email.trim().length > 0) {
      submitEmailDetails(fName, sName, email);
      gamificationBackPage();
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const goBack = () => {
    setShowLeaveAnime(true);
    setShowEnterAnime(false);
    if (
      applicatinFlags?.isRedirectedFromGiftingSuccessPage &&
      applicatinFlags?.offerId
    ) {
      new ApplicationFlag({
        id: "",
        isShowFooter: true,
        isRedirectedFromGiftingSuccessPage: false,
        offerId: 0
      }).$save();
      setTimeout(() => history.push("/home"), 500);
    } else {
      setTimeout(() => {
        var oldUrl = window.location.href;
        window.history.back();
        setTimeout(() => {
          if (window.location.href === oldUrl) {
            history.push("/home");
          }
        }, 10);
      }, 500);
    }
  };
  const redirectToHome = () => {
    setTimeout(() => history && history.push("/home"), 500);
  };

  const gamificationBackPage = () => {
    setDialogType(PreClaimDialogTypes.LOADER);
    setOpenDialog(true);
    try {
      updateClaimedOffersFromApi(offers, offer, claimedOffers, offerClaimed)
        .then(res => {
          setShowIWJourney(false);
          setShowLDJourney(false);
          setShowSTWJourney(false);
          setSTWEmailCapture(false);
          setSTWWinnerModal(false);
          handleCloseDialog();
        })
        .catch(err => {
          if (err?.networkError) {
            history.push("/error-500");
          } else {
            setShowIWJourney(false);
            setShowLDJourney(false);
            handleCloseDialog();
            showSnackBar();
          }
        });
    } catch (error: any) {
      if (error?.networkError) {
        history.push("/error-500");
      } else {
        setShowIWJourney(false);
        setShowLDJourney(false);
        handleCloseDialog();
        showSnackBar();
      }
    }
  };

  const submitEmailDetails = async (firstname, lastname, email) => {
    let claimDetails: ClaimedOffer | null = getLatestClaimDetailsByClaimId(
      offers[id].claimedIds,
      claimedOffers
    );
    let OfferPrizeName = prizeName == "" ? offer?.title : prizeName;
    try {
      const res = await gamifiactionEmailGraphQLCall(
        claimDetails?.claimedId || "",
        firstname,
        lastname,
        email,
        userData?.externalRegistrationId,
        userData?.externalId,
        OfferPrizeName,
        offer?.offerId
      );
      if (res?.data?.id || res?.data?.saveGamificationDetails?.id) {
        //  Email Submiited Succesfully - Popup or Error Message
        showSnackBar("Email submitted successfully");
      }
    } catch (error: any) {
      console.log("Email Not Submitted");
      reFetchConditions();
      handleCloseDialog();
      setShowIWJourney(false);
      setShowLDJourney(false);
      setShowSTWJourney(false);
      if (error?.networkError) {
        history.push("/error-500");
      } else {
        showSnackBar();
      }
    }
  };

  const onOfferClick = () => {
    setCardVisibility(false);
    setTimeout(() => {
      setCardVisibility(true);
      setShowEnterAnime(true);
    }, 100);
  };

  const titleDefaultStyles = {
    opacity: 0,
    marginTop: -40
  };

  const titleEnterStyles = {
    marginTop: spring(0),
    opacity: spring(1)
  };

  const titleLeaveStyles = {
    marginTop: spring(-40),
    opacity: spring(0)
  };

  const buttonDefaultStyles = {
    marginBottom: -100,
    opacity: 0
  };
  const buttonEnterStyles = {
    marginBottom: spring(0, { stiffness: 400 }),
    opacity: spring(1)
  };
  const buttonExitStyles = {
    opacity: spring(0)
  };

  const showJourney = () => {
    if (showIWJourney) {
      return (
        <InstantWinJourney
          type={iwJourneyType}
          onEmailSubmitCallBack={onEmailSubmitCallBack}
          successCallback={preClaimSuccessAction}
          winCallback={navigateCodeRedemption}
          handleCloseDialog={() => {
            handleCloseDialog();
            setShowIWJourney(false);
          }}
          iwWinImage={iwWinImage}
          iwLooseImage={iwLooseImage}
          iwIntroImage={iwIntroImage}
          iwPopBalloonImage={iwPopBalloonImage}
          clientName={clientName}
          CMS_iwWinImage={CMS_iwWinImage}
          CMS_iwLooseImage={CMS_iwLooseImage}
          CMS_iwIntroImage={CMS_iwIntroImage}
          showEmail={showEmailPopup}
          goBack={gamificationBackPage}
          isEmailCapture={emailCapture}
          iw_error_message={iw_error_message}
          iw_intro_message={CMS_iwIntroText}
          iw_winner_message={CMS_iwWinnerText}
          iw_loser_message={CMS_iwLoserText}
          std_intro_text={std_intro_text}
          std_winner_text={std_winner_text}
          std_loser_text={std_loser_text}
        />
      );
    } else if (showLDJourney) {
      return (
        <LuckyDipJourney
          type={ldJourneyType}
          successCallback={preClaimSuccessAction}
          winCallback={navigateCodeRedemption}
          handleCloseDialog={() => {
            handleCloseDialog();
            setShowLDJourney(false);
          }}
          LdNewIntroSolo={LdNewIntroSolo}
          ldIntroScreenImage={ldIntroScreenImage}
          ldClawIntroScreenImage={ldClawIntroScreenImage}
          ldConveyorbeltIntroScreenImage={ldConveyorbeltIntroScreenImage}
          ldPickToWinBgImage={ldPickToWinBgImage}
          ldCongratsImage={ldCongratsImage}
          clientName={clientName}
          prizeName={prizeName}
          ldStaticPrizeInterimImage={ldStaticPrizeInterimImage}
          CMS_lucky_dip_intro_background={CMS_lucky_dip_intro_background}
          CMS_lucky_dip_intro_animation={CMS_lucky_dip_intro_animation}
          CMS_lucky_dip_play_animation={CMS_lucky_dip_play_animation}
          CMS_lucky_dip_congrats_animation={CMS_lucky_dip_congrats_animation}
          showEmail={showEmailPopup}
          goBack={gamificationBackPage}
          isEmailCapture={
            showEmailPopup ? (showEmailPopup !== "NONE" ? true : false) : false
          }
          onEmailSubmitCallBack={onEmailSubmitCallBack}
        />
      );
    } else if (showSTWJourney) {
      return (
        <SpinToWin
          spinTowinConfig={spinTowinConfig}
          offer={offers[id]}
          submitEmailData={onEmailSubmitCallBack}
          setShowSTWJourney={setShowSTWJourney}
          emailCapture={stwEmailCapture}
          setEmailCapture={setSTWEmailCapture}
          openWinnerModal={stwWinnerModal}
          setOpenWinnerModal={setSTWWinnerModal}
          type={stwJourneyType}
          reFetchConditions={reFetchConditions}
          CMS_spin_to_win_background_img={CMS_spin_to_win_background_img}
          CMS_spin_to_win_foreground_img={CMS_spin_to_win_foreground_img}
          CMS_spin_to_win_winner_img={CMS_spin_to_win_winner_img}
          segments={spinToWinSegements}
          segementColors={spinToWinColors}
        />
      );
    } else {
      return null;
    }
  };

  const loadVisitToWinCount = () => {
    return (
      <>
        <div className={classes.keyTermsContainer}>
          <h2 className={classes.prizeheading} id="prize-heading">
            {ConstantMapping[clientName].VISIT_TO_WIN_PRIZE_HEADING}
          </h2>
          <Typography className={classes.prizeSubHeading} id="prize-subheading">
            {ConstantMapping[clientName].VISIT_TO_WIN_PRIZE_SUBHEADING}
          </Typography>
          <div className={classes.visitCountContainer} id="visitCount">
            <img
              src={ImagesMapping[clientName].visit_To_Win_Animated_Heart}
              alt="visitToWin Count"
              className={classes.visitToWinImg}
            />
            <h3 className={classes.visitToCountCount}>{offerEntries}</h3>
          </div>
        </div>
      </>
    );
  };

  const loadSpinToWinCount = () => {
    return (
      <>
        <div className={classes.keyTermsContainer}>
          <h2 className={classes.prizeheading} id="prize-heading">
            {ConstantMapping[clientName].SPIN_TO_WIN_PRIZE_HEADING}
          </h2>
          <Typography className={classes.prizeSubHeading} id="prize-subheading">
            {ConstantMapping[clientName].SPIN_TO_WIN_PRIZE_SUBHEADING}
          </Typography>
          <div className={classes.visitCountContainer} id="visitCount">
            <img
              src={ImagesMapping[clientName].visit_To_Win_Animated_Heart}
              alt="visitToWin Count"
              className={classes.visitToWinImg}
            />
            <h3 className={classes.visitToCountCount}>
              {offers[id]?.prizeEntries}
            </h3>
          </div>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <PreClaimDialog
        openDialog={openDialog}
        type={dialogType}
        claimAdvice={offer?.preClaimAdvice}
        clientName={clientName}
        handleCloseDialog={handleCloseDialog}
        confirmCallback={preClaimSuccessAction}
        getButtonText={() => {
          return getButtonText(getArticleValue());
        }}
        title={offer?.title}
      />
      {showLDJourney || showIWJourney || showSTWJourney ? (
        showJourney()
      ) : (
        <>
          <Header
            headerText=""
            isHeaderInverted={false}
            svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
            clientLogoPrimary={getImageDetails(
              ImagesMapping[clientName].clientLogoPrimary,
              ConstantMapping[clientName].CLIENT_ICON_TEXT
            )}
            clientLogoSecondary={getImageDetails(
              ImagesMapping[clientName].clientLogoSecondary,
              ConstantMapping[clientName].CLIENT_ICON_TEXT
            )}
            onSvgSelection={goBack}
            onLogoSelection={redirectToHome}
            isScrollHeader={false}
          />

          <div className={classes.wrapper}>
            {showCard && renderCard()}
            <div className={classes.divider}></div>
            {showCard && (
              <>
                <AnimationHelper
                  defaultStyles={titleDefaultStyles}
                  enterStyles={titleEnterStyles}
                  exitStyles={titleLeaveStyles}
                  showEnterAnimation={showEnterAnime}
                  showExitAnimation={showLeaveAnime}
                  enterAnimationDelay={650}
                  exitAnimationDelay={100}
                  units={{
                    marginTop: "px"
                  }}
                >
                  <div
                    className={
                      appAccessLevel !== ("PAYG_PLUS_LITE" || "PAYG_LITE")
                        ? classes.mainContainer
                        : classes.restrictedContainer
                    }
                    id="offer-main-container"
                  >
                    {isVisitToWin ? loadVisitToWinCount() : null}
                    {isSpinToWin && offers[id]?.prizeEntries
                      ? loadSpinToWinCount()
                      : null}
                    {isVisitToWin && renderAdditionalDescription()}
                    {renderDescription()}
                    {vmrLiteUserStatus ? (
                      <>
                        {!offer?.keyTerms ? "" : renderKeyTermsSection()}
                        {!offer?.entryMechanics
                          ? ""
                          : renderEntryMechanicsSection()}
                        {!offer?.termsAndConditions
                          ? ""
                          : renderTermsAndConditions()}
                      </>
                    ) : (
                      renderRestrictedOfferSection()
                    )}
                  </div>
                  {vmrLiteUserStatus && (
                    <div
                      className={`${classes.buttonContainer} ${
                        isVisitToWin && classes.visitToWinBtn
                      }`}
                      id="btn-Element"
                      style={{ bottom: "" }}
                    >
                      <AnimationHelper
                        defaultStyles={buttonDefaultStyles}
                        enterStyles={buttonEnterStyles}
                        exitStyles={buttonExitStyles}
                        showEnterAnimation={showEnterAnime}
                        showExitAnimation={showLeaveAnime}
                        enterAnimationDelay={800}
                        units={{
                          marginBottom: "px"
                        }}
                      >
                        {renderButton()}
                      </AnimationHelper>
                    </div>
                  )}
                </AnimationHelper>
                <AnimationHelper
                  defaultStyles={titleDefaultStyles}
                  enterStyles={titleEnterStyles}
                  exitStyles={titleLeaveStyles}
                  showEnterAnimation={showEnterAnime}
                  showExitAnimation={showLeaveAnime}
                  enterAnimationDelay={1200}
                  units={{
                    marginBottom: "px"
                  }}
                >
                  <div className={classes.recommendedOffersContainer}>
                    {isArticleOffer ? (
                      <>
                        <div className={classes.recommendedOfferSection}>
                          <h2 className={classes.recommendedOfferText}>
                            {
                              ConstantMapping[clientName]
                                .RECOMMENDED_OFFERS_TEXT
                            }
                          </h2>
                          <div className={classes.recommendedOffers}>
                            <RecommendedOffers
                              filterOfferIds={[+id]}
                              onOfferClick={onOfferClick}
                            ></RecommendedOffers>
                          </div>
                        </div>
                      </>
                    ) : id === checkSpecificBanner ? (
                      ""
                    ) : (
                      <div
                        ref={reviewSectionRef}
                        className={classes.offerRatingSection}
                      >
                        {rateOffer(offer)}
                      </div>
                    )}
                  </div>
                </AnimationHelper>
                {/* {!isVMRLiteUser  || (appAccessLevel !=="PAYG_LITE") && (
                  <AnimationHelper
                    defaultStyles={titleDefaultStyles}
                    enterStyles={titleEnterStyles}
                    exitStyles={titleLeaveStyles}
                    showEnterAnimation={showEnterAnime}
                    showExitAnimation={showLeaveAnime}
                    enterAnimationDelay={1200}
                    units={{
                      marginTop: "px"
                    }}
                  >
                    <div className={classes.recommendedOffersContainer}>
                      <div className={classes.recommendedOfferSection}>
                        <h2 className={classes.recommendedOfferText}>
                          {ConstantMapping[clientName].RECOMMENDED_OFFERS_TEXT}
                        </h2>
                        <div className={classes.recommendedOffers}>
                          <RecommendedOffers
                            filterOfferIds={[+id]}
                            onOfferClick={onOfferClick}
                          ></RecommendedOffers>
                        </div>
                      </div>
                    </div>
                  </AnimationHelper>
                )} */}
              </>
            )}
          </div>
        </>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const offers = Offers.getInsatnce("", state)?.props;
  const favorites = FavouriteOffers.getInsatnce("", state)?.props;
  const merchants = Merchants.getInsatnce("", state)?.props;
  let claimedOffers = ClaimedOffers.getInsatnce("", state)?.props;
  let clientData = ClientModel.getInsatnce("", state)?.props;
  let userData = UserDetails.getInsatnce("", state)?.props;
  let iw_error_messagedata = AppConfig.getInsatnce(
    "instant-win-losing-message",
    state
  )?.props;
  const iw_error_message = iw_error_messagedata?.value[0]?.value;
  const displayCategories = DisplayCategories.getInsatnce("", state)?.props;
  const displayCategoryIds = DisplayCategoryIds.getInsatnce("", state)?.props
    .ids;
  const sortedClaimedIds = SortedClaimedIds.getInsatnce("", state)?.props;
  const favouriteOfferIds = SortedFavouriteOfferIds.getInsatnce(
    "",
    state
  )?.props;
  const CMSimages = CMSImages.getInsatnce("", state)?.props;
  let applicatinFlags = ApplicationFlag.getInsatnce("", state).props;
  const spinTowinConfig = CMSImages.getInsatnce("", state)?.props
    ?.CMS_spinToWinConfigurations;

  return {
    offers,
    favorites,
    merchants,
    claimedOffers,
    clientData,
    userData,
    displayCategories,
    displayCategoryIds,
    sortedClaimedIds,
    favouriteOfferIds,
    applicatinFlags,
    CMSimages,
    iw_error_message,
    spinTowinConfig
  };
};
export default connect(mapStateToProps)(OfferDetails);
