import { useEffect, useRef, useState } from "react";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { Button, Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import { useStyles } from "./styles";
import { useHistory, useParams } from "react-router-dom";
import { Header } from "gce-cxinteracts-ui-components-library";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import GenericDialog from "Components/Dialog/GenericDialog";
import { claimOfferGraphQLCall } from "Services/OfferSerivice";
import ClaimedOffers, {
  ClaimedOffer,
  IClaimedOffers
} from "Models/ReduxModels/ClaimedOffersModel";
import SortedClaimedIds, {
  ClaimIds
} from "Models/ReduxModels/SortedClaimedIds";
import Offers, { ISpinTowWinConfig } from "Models/ReduxModels/OffersModel";
import { getLatestClaimDetailsByClaimId } from "Utils/offerUtils";
import { SpinToWinTypes } from "Models/Interfaces/PreClaimDialogTypes";
import { showSnackBar } from "Utils/SnackBarUtils";
import UserDetails from "Models/ReduxModels/UserDetails";

export interface IProps {
  spinTowinConfig: ISpinTowWinConfig;
  offer: any;
  userDetails: any;
  setShowSTWJourney: (data?) => void;
  submitEmailData: (data?) => void;
  isOnlyOnce?: boolean;
  size?: number;
  upDuration?: number;
  downDuration?: number;
  ctaDetails?: ICTADetails;
  primaryColor?: string;
  contrastColor?: string;
  claimedOffers: IClaimedOffers;
  sortedClaimedIds: ClaimIds;
  offers: any;
  match?: any;
  openWinnerModal: boolean;
  setOpenWinnerModal: (data?) => void;
  reFetchConditions: (data?) => void;
  emailCapture: boolean;
  setEmailCapture: (data?) => void;
  type: number;
  CMS_spin_to_win_foreground_img: any;
  CMS_spin_to_win_background_img: any;
  CMS_spin_to_win_winner_img: any;
  segments: string[];
  segementColors: string[];
}

export interface ICTADetails {
  label: string;
  colors: string;
}

export interface IRouteParams {
  id: string;
}

const SpinToWin: React.FC<IProps> = props => {
  const {
    offer,
    userDetails,
    submitEmailData,
    setShowSTWJourney = () => {},
    isOnlyOnce = true,
    size = window.innerWidth / 3,
    upDuration = 500,
    downDuration = 1500,
    ctaDetails = { label: "SPIN", colors: "#a70303, #a70303" },
    primaryColor = "#d61d24",
    contrastColor = "white",
    claimedOffers,
    sortedClaimedIds,
    offers,
    openWinnerModal,
    setOpenWinnerModal,
    emailCapture,
    setEmailCapture,
    type,
    reFetchConditions,
    CMS_spin_to_win_foreground_img,
    CMS_spin_to_win_background_img,
    CMS_spin_to_win_winner_img,
    segementColors,
    segments
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<IRouteParams>();
  const { id } = params;
  const claimedOffer = getLatestClaimDetailsByClaimId(
    offers[offer?.offerId].claimedIds,
    claimedOffers
  );
  const [winnerLabel, setWinnerLabel] = useState<string>("");
  const [isSpinStarted, setIsSpinStarted] = useState(false);

  const onFinished = winner => {
    setTimeout(() => {
      setOpenWinnerModal(true);
      setWinnerLabel(winner);
    }, 1000);
  };

  const goBack = () => {
    setTimeout(() => {
      setShowSTWJourney(false);
    }, 500);
  };

  const redirectToHome = () => {
    setTimeout(() => history && history.push("/home"), 500);
  };

  const claimHandler = () => {
    const redemptionOfferData = offer?.redemptionDetails?.filter(
      item => item?.prizeName === winnerLabel
    );
    let claimDetails: ClaimedOffer | null = claimedOffer;

    if (redemptionOfferData?.[0]?.captureEmail) {
      setEmailCapture(true);
    } else {
      if (claimDetails) {
        history.push(`/code/${claimDetails.claimedId}`);
      }
    }
  };

  const clientName = getClientName();

  const randomString = () => {
    const chars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".split("");
    const length = 8;
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars[Math.floor(Math.random() * chars.length)];
    }
    return str;
  };

  const canvasId = useRef(`canvas-${randomString()}`);
  const wheelId = useRef(`wheel-${randomString()}`);
  const dimension = (size + 20) * 2;
  const innerCircleRadius = 20;
  let currentSegment = "";
  let isStarted = false;
  let timerHandle = 0;
  const timerDelay = segments?.length + 10;
  let angleCurrent = 0;
  let angleDelta = 0;
  let canvasContext: CanvasRenderingContext2D | null = null;
  let maxSpeed = Math.PI / (segments?.length * 6);

  const upTime = segments?.length * upDuration;
  const downTime = segments?.length * downDuration;
  let spinStart = 0;
  let frames = 0;
  const centerX = size + 20;
  const centerY = size + 20;

  useEffect(() => {
    if (type === SpinToWinTypes.WIN) {
      setEmailCapture(true);
    } else {
      wheelInit();
      setTimeout(() => {
        window.scrollTo(0, 1);
      }, 0);
    }
  }, [type]);

  // to check that color is dark or light luminance.
  function getLabelColor(hexColor) {
    hexColor = hexColor.replace("#", "");

    // Convert hex to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate brightness
    const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

    // Determine if it's light or dark
    if (brightness < 128) {
      return "#ffffff";
    } else {
      return "#000000";
    }
  }

  const wheelInit = () => {
    initCanvas();
    wheelDraw();
  };

  const initCanvas = () => {
    let canvas: HTMLCanvasElement | null = document.getElementById(
      canvasId.current
    ) as HTMLCanvasElement;

    if (navigator.userAgent.indexOf("MSIE") !== -1) {
      canvas = document.createElement("canvas");
      canvas.setAttribute("width", `${dimension}`);
      canvas.setAttribute("height", `${dimension}`);
      canvas.setAttribute("id", canvasId.current);
      document.getElementById(wheelId.current)?.appendChild(canvas);
    }
    canvas?.addEventListener("click", spinHandler, false);
    canvasContext = canvas?.getContext("2d");
  };

  const spinHandler = async () => {
    if (!isSpinStarted) {
      setIsSpinStarted(true);
      isStarted = true;
      try {
        let res = await claimOfferGraphQLCall(offer?.offerId);
        reFetchConditions();
        let claimedOffer = res.data?.claimOffer;
        let sortedclaimedIds: number[] = sortedClaimedIds?.claimIds;

        if (claimedOffer) {
          let currentClaimedOffer: any = {
            claimedId: claimedOffer?.id,
            offerId: claimedOffer?.offerId,
            attributes: claimedOffer?.attributes,
            byAgent: claimedOffer?.byAgent,
            claimDateTime: new Date(claimedOffer?.claimDateTime),
            expiryDateTime: claimedOffer?.expiryDateTime
              ? new Date(claimedOffer?.expiryDateTime)
              : undefined,
            offerType: claimedOffer?.offerType,
            redemptionCode: claimedOffer?.redemptionCode,
            prizeName: claimedOffer?.prizeName,
            prizeType: claimedOffer?.prizeType,
            prizeEntries: claimedOffer?.prizeEntries
          };
          sortedclaimedIds.splice(0, 0, claimedOffer.id);
          let newClaimedOffers = claimedOffers || {};
          newClaimedOffers[currentClaimedOffer.claimedId] = currentClaimedOffer;

          let updatedOffers = offers;
          if (updatedOffers[id].claimedIds?.length) {
            updatedOffers[id].claimedIds.push(claimedOffer.id);
          } else {
            updatedOffers[id].claimedIds = [claimedOffer.id];
          }

          // updating the prizeEntries value after claiming the offer.
          const updatedOffer = {
            ...offer,
            prizeEntries: claimedOffer?.prizeEntries
          };
          new Offers({ ...offers, [id]: updatedOffer }).$save();

          if (timerHandle === 0 && res?.data?.claimOffer?.prizeName) {
            spinStart = new Date().getTime();
            maxSpeed = Math.PI / (segments?.length * 3);
            frames = 0;
            timerHandle = window.setInterval(
              () => onTimerTick(res?.data?.claimOffer?.prizeName),
              timerDelay
            );
          }
        } else {
          console.log("Error__ ", res);
          showSnackBar();
        }
      } catch (error) {
        console.log("Error__ ", error);
        showSnackBar();
      }
    }
  };

  const onTimerTick = winningSegment => {
    frames++;
    wheelDraw();

    const duration = new Date().getTime() - spinStart;
    let progress = 0;
    let finished = false;
    if (duration < upTime) {
      progress = duration / upTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
    } else {
      if (winningSegment) {
        if (currentSegment === winningSegment && frames > segments?.length) {
          // calculating the radians we have to move according to segment angle.
          // calculating the 2292.72% of segment angle.
          let radians = 2292.72 * (segmentAng / 100);
          angleCurrent += (radians / 2) * (Math.PI / 180);
          wheelDraw();
          angleCurrent += (radians / 2) * (Math.PI / 180);
          wheelDraw();
          progress = 1;
        } else {
          progress = duration / downTime;
          angleDelta =
            maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        }
      }
      if (progress >= 1) finished = true;
    }

    angleCurrent += angleDelta;
    while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2;
    if (finished) {
      onFinished(winningSegment);
      clearInterval(timerHandle);
      timerHandle = 0;
      angleDelta = 0;
    }
  };

  const wheelDraw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const drawSegment = (key: number, lastAngle: number, angle: number) => {
    if (!canvasContext) {
      return false;
    }
    const ctx = canvasContext;
    const value = segments[key];

    const gapSize = 5 / size;
    const adjustedLastAngle = lastAngle + gapSize / 2;
    const adjustedAngle = angle - gapSize / 2;

    // Draw the segment with space between them
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, adjustedLastAngle, adjustedAngle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();

    // Cycle through segementColors by using modulo operator on the segment index
    const colorSet = segementColors[key % segementColors.length]?.split(", ");

    // Create a gradient using the colors in the color set
    const gradient = ctx.createLinearGradient(0, 0, dimension, 0);
    gradient.addColorStop(0, colorSet[0]);
    gradient.addColorStop(0.33, colorSet[1]);
    gradient.addColorStop(0.66, colorSet[2]);
    gradient.addColorStop(1, colorSet[3]);

    ctx.fillStyle = gradient;
    ctx.fill();
    ctx.stroke();

    // Add text to the segment
    ctx.save();
    ctx.translate(centerX, centerY);
    const textAngle = (adjustedLastAngle + adjustedAngle) / 2;
    ctx.rotate(textAngle);

    // Calculate segment dimensions
    const segmentWidth = (Math.PI * size) / segments?.length; // Approximate width of the segment arc

    // Calculate the font size based on segment height and width (same for all segments)
    const fontSizeToFit = 10;
    const lineHeight = fontSizeToFit * 1.2;
    ctx.fillStyle = getLabelColor(colorSet[1]);
    const startY = -lineHeight / 2; // Center text vertically

    // Checking length and then inserting the label into the canvas
    if (value.length >= 16) {
      const splittedVal = value.split(" ");
      let line1 = "";
      let line2 = "";
      for (let i = 0; i < splittedVal?.length; i++) {
        if (i < splittedVal?.length / 2 && line1.length < 15)
          line1 += `${splittedVal[i]} `;
        else line2 += `${splittedVal[i]} `;
      }
      ctx.font = "normal 12px VodafoneRgBold";
      ctx.fillText(line1, size / 2 + 20, startY, segmentWidth + 30);
      line2 &&
        ctx.fillText(line2, size / 2 + 20, startY + 13, segmentWidth + 30);
    } else {
      ctx.font = "normal 12px VodafoneRgBold";
      ctx.fillText(value, size / 2 + 20, startY + 8, segmentWidth + 30);
    }

    ctx.restore();
  };

  let segmentAng;
  const drawWheel = () => {
    if (!canvasContext) {
      return false;
    }
    const ctx = canvasContext;
    let lastAngle = angleCurrent;
    const len = segments?.length;
    const PI2 = Math.PI * 2;

    // Draw the segments of the wheel
    ctx.lineWidth = 1;
    ctx.strokeStyle = primaryColor;
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.font = "normal 12px VodafoneRgBold";

    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent;
      segmentAng = angle - lastAngle;
      drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }

    // Draw a center circle (same color as needle)
    ctx.beginPath();
    ctx.arc(centerX, centerY, innerCircleRadius, 0, PI2, false);
    ctx.closePath();
    // Create a gradient using the colors in the color set
    const colorSet = ctaDetails?.colors?.split(", ");
    const gradient = ctx.createLinearGradient(
      centerX - innerCircleRadius,
      centerY + innerCircleRadius,
      centerX + innerCircleRadius,
      centerY - innerCircleRadius
    );

    gradient.addColorStop(0, colorSet[0]);
    gradient.addColorStop(1, colorSet[1]);
    ctx.fillStyle = gradient;
    ctx.fill();

    // Draw a border around the circle.
    ctx.lineWidth = 1;
    ctx.strokeStyle = primaryColor;
    ctx.beginPath();
    ctx.arc(
      centerX,
      centerY,
      innerCircleRadius,
      Math.PI / 2,
      (3 * Math.PI) / 2
    );
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(
      centerX,
      centerY,
      innerCircleRadius,
      (3 * Math.PI) / 2,
      Math.PI / 2
    );
    ctx.stroke();

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, size, 0, PI2, false);
    ctx.closePath();

    // Draw mid circle having black color background having low opacity.
    ctx.beginPath();
    ctx.arc(centerX, centerY, size / 2 + 20, 0, Math.PI * 2, false); // Draw a circle
    ctx.fillStyle = "rgba(0,0,0,0.04)"; // Set the fill color

    ctx.fill(); // Fill the circle
    ctx.closePath();

    // Draw the "Spin" label in the inner circle
    ctx.fillStyle = contrastColor;
    ctx.font = "normal 12px VodafoneRgBold";
    ctx.fillText(ctaDetails?.label, centerX, centerY);
  };

  const drawNeedle = () => {
    if (!canvasContext) {
      return false;
    }
    const ctx = canvasContext;

    ctx.lineWidth = 1;
    // Create a gradient using the colors in the color set
    const colorSet = ctaDetails?.colors?.split(", ");
    const gradient = ctx.createLinearGradient(
      centerX - innerCircleRadius,
      centerY + innerCircleRadius,
      centerX + innerCircleRadius,
      centerY - innerCircleRadius
    );

    gradient.addColorStop(0, colorSet[0]);
    gradient.addColorStop(1, colorSet[1]);
    ctx.fillStyle = gradient;

    // Draw the needle as a triangle
    ctx.beginPath();
    ctx.moveTo(centerX + 5, centerY - 18);
    ctx.lineTo(centerX - 5, centerY - 18);
    ctx.lineTo(centerX, centerY - 35);
    ctx.closePath();
    ctx.fill();

    // Calculate and display the current segment
    const change = angleCurrent + Math.PI / 2;
    let i =
      segments?.length -
      Math.floor((change / (Math.PI * 2)) * segments?.length) -
      1;
    if (i < 0) i = i + segments?.length;
    currentSegment = segments?.[i];

    // Display the current segment below the wheel
    isStarted &&
      ctx.fillText(currentSegment, centerX + 10, centerY + size + 50);
  };

  const clear = () => {
    if (!canvasContext) {
      return false;
    }
    const ctx = canvasContext;
    ctx.clearRect(0, 0, dimension, dimension);
  };

  return (
    <>
      <Header
        headerText=""
        isHeaderInverted={false}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={goBack}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div
        className={classes.spinWinMainContainer}
        style={{
          backgroundImage: `url(${CMS_spin_to_win_background_img?.src})`
        }}
      >
        <div className={classes.wheelWrapper}>
          {Object?.keys(offer)?.length ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                position: "relative",
                pointerEvents: isSpinStarted && isOnlyOnce ? "none" : "auto"
              }}
              onClick={spinHandler}
              id={wheelId.current}
            >
              {CMS_spin_to_win_foreground_img?.src && (
                <img
                  src={CMS_spin_to_win_foreground_img?.src}
                  height={size * 3 + 190}
                  width={size * 3 + 190}
                  style={{
                    position: "absolute",
                    top: "-8.2rem",
                    left: "-6.3rem"
                  }}
                />
              )}
              <canvas
                id={canvasId.current}
                width={dimension}
                height={dimension}
                style={{
                  pointerEvents: isSpinStarted && isOnlyOnce ? "none" : "auto"
                }}
              />
            </div>
          ) : null}

          <div className={classes.brandLogosContainer}>
            <img
              src={ImagesMapping[clientName].stw_logos}
              className={classes.brandLogos}
            />
          </div>

          {/* display the winning label */}
          <Dialog
            open={openWinnerModal}
            PaperProps={{
              classes: {
                root: classes.dialogPaperRoot
              },
              style: {
                backgroundImage: `url(${CMS_spin_to_win_winner_img?.src})`
              }
            }}
          >
            <div className={classes.dialogMidContainer}>
              <p
                className={classes.dialogWinnerLabelDesc}
              >{`You’ve won ${winnerLabel}`}</p>

              {claimedOffer?.prizeEntries &&
              claimedOffer?.prizeType ===
                ConstantMapping[getClientName()].GRAND_PRIZE ? (
                <p
                  style={{ marginTop: 0 }}
                  className={classes.dialogWinnerLabelDesc}
                >
                  Number of entries won: {claimedOffer?.prizeEntries}
                </p>
              ) : null}
              <Button
                variant="contained"
                color="secondary"
                className={classes.cliamNowButton}
                onClick={claimHandler}
              >
                {claimedOffer?.prizeType ===
                ConstantMapping[getClientName()].GRAND_PRIZE
                  ? "Show Entries"
                  : "Claim Now"}
              </Button>
            </div>
          </Dialog>

          <GenericDialog
            openDialog={emailCapture}
            content={"EMAILTYPE"}
            clientName={clientName}
            handleCloseDialog={() => {
              setEmailCapture(false);
              setShowSTWJourney(false);
            }}
            showCloseIcon={true}
            heading="Tell us where to send your prize"
            paperStyle={{ backgroundColor: "transparent", padding: 0 }}
            closeIconBtnStyle={ConstantMapping[clientName].SVG.headerSvgStyle}
            showFullScreenDialog={true}
            showEmailPopUp={true}
            confirmCallback={data => submitEmailData(data)}
            isHeaderTransparent={true}
          ></GenericDialog>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  let claimedOffers = ClaimedOffers.getInsatnce("", state)?.props;
  const sortedClaimedIds = SortedClaimedIds.getInsatnce("", state)?.props;
  const offers = Offers.getInsatnce("", state)?.props;
  const userDetails = UserDetails.getInsatnce("", state)?.props;

  return {
    claimedOffers,
    sortedClaimedIds,
    offers,
    userDetails
  };
};
export default connect(mapStateToProps)(SpinToWin);
