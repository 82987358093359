import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import QRBarcode from "Components/QRBarcode/QRBarcode";
import { useStyles } from "./styles";
import ClaimedOffers from "Models/ReduxModels/ClaimedOffersModel";
import Offers, {
  RedemptionCodeType,
  RedemptionMethod,
  RedemptionExpiryModel,
  RedemptionDetail
} from "Models/ReduxModels/OffersModel";
import {
  Popover,
  Typography,
  Button,
  Card,
  CardContent
} from "@material-ui/core";
import { OfferType } from "Models/Interfaces/OfferType";
import { Header, CountDownTimer } from "gce-cxinteracts-ui-components-library";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import RedemptionGuidelines from "./RedemptionGuidelines";
import GiftingDialog from "./GiftingDialog";
import PrizeDrawRedemption from "./PrizeDrawRedemption";
import {
  getImageDetails,
  getClientName,
  computeTicks,
  loadEvents,
  previousPages,
  addExternalSupport
} from "Utils/GeneralUtils";
import GiftedScreen from "./GiftedScreen";
import {
  isOfferCodeExpired,
  updateClaimedOffersFromApi
} from "Utils/offerUtils";
import UserDetails from "Models/ReduxModels/UserDetails";
import { useHistory } from "react-router-dom";
import { showSnackBar } from "Utils/SnackBarUtils";
import ClipboardJS from "clipboard";
import { ImagesMapping } from "Configurations/ImagesMapping";
interface IProps {
  offers: Offers;
  claimedOffers: ClaimedOffers;
  userDetails: UserDetails;
}

const noCodeText = "Code no longer available";

const RedemptionCode: React.FC<IProps> = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = props.match.params;
  const claimedOffer = props.claimedOffers?.[id];
  const currentOffer = props.offers?.[claimedOffer?.offerId];
  // const isExpiryModelNone =
  //   currentOffer?.redemptionExpiryModel === RedemptionExpiryModel.None;
  const currentOfferIsExpiredCode = isOfferCodeExpired(currentOffer, id);
  const appVersion = props.userDetails?.appVersion;
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [popoverMessage, setPopoverMessage] = React.useState<string>("Copied!");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isGifted, setIsGifted] = React.useState(false);
  const [selctedDialogOption, setSelctedDialogOption] = React.useState<
    string | null
  >(null);
  const clientName = getClientName();
  const [height, setHeight] = React.useState<any>(0);
  let isPrizeDraw = currentOffer?.offerType === OfferType.PRIZEDRAW;
  let isVisitToWin = currentOffer?.offerType === OfferType.VISIT_TO_WIN;

  //To get the redemption details
  const setRedemptionDetails = (details: any, claimedOffer: any) => {
    let redemptionObj: any = [];
    if (
      claimedOffer.offerType == ConstantMapping[clientName].LD_TYPE ||
      claimedOffer?.offerType === ConstantMapping[clientName].STW_TYPE
    ) {
      Object.keys(details).forEach(detail => {
        if (details[detail]?.prizeName === claimedOffer.prizeName) {
          redemptionObj.push(details[detail]);
        }
      });
    }
    if (redemptionObj.length > 0) {
      return redemptionObj;
    } else {
      return details;
    }
  };

  const redemptionDetails = setRedemptionDetails(
    currentOffer?.redemptionDetails,
    claimedOffer
  );

  //To get the expiry model
  const setExpiryModel = (currentOffer, claimedOffer) => {
    let isExpiry: Boolean = false;
    if (
      (currentOffer.offerType === OfferType.LUCKY_DIP &&
        claimedOffer.offerType == ConstantMapping[clientName].LD_TYPE) ||
      (currentOffer.offerType === OfferType.SPIN_TO_WIN &&
        claimedOffer.offerType == ConstantMapping[clientName].STW_TYPE)
    ) {
      let details = currentOffer?.offerPrizeDetails;
      Object.keys(details).forEach(detail => {
        if (details[detail]?.prizeName === claimedOffer.prizeName) {
          isExpiry = details[detail]?.voucherCodeExpiryModelType === "None";
        }
      });
    } else {
      isExpiry =
        currentOffer?.redemptionExpiryModel === RedemptionExpiryModel.None;
    }
    return isExpiry;
  };

  const isExpiryModelNone = setExpiryModel(currentOffer, claimedOffer);

  // const isExpiryModelNone =
  // currentOffer?.redemptionExpiryModel === RedemptionExpiryModel.None;

  useEffect(() => {
    initializeClipboard();
    const redemptionDom = document.getElementById("redemption-container");
    addExternalSupport(redemptionDom);
  }, []);

  useEffect(() => {
    let el = document.getElementById("coundownTitle");
    let titleHeight = el?.offsetHeight;
    setHeight(titleHeight);
  }, [document.getElementById("coundownTitle")]);

  let showTimer = false;

  if (currentOffer) {
    currentOffer.redemptionDetails?.map(details => {
      if (
        details.redemptionMethod !== RedemptionMethod.AUTOMATIC &&
        claimedOffer?.prizeType !== ConstantMapping[getClientName()].GRAND_PRIZE
      ) {
        showTimer = true;
      }
    });
  }

  const tealiumEventTagging = () => {
    const state = {
      onLoadParams: {
        page_subsection: "offers",
        page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${currentOffer?.title}${ConstantMapping[clientName].TEALIUM_PAGE_GIFTING_OVERLAY}`
        // same_page_event: true
      }
    };
    loadEvents(previousPages, { state });
  };

  const handleClickOpenDialog = () => {
    if (
      ConstantMapping[clientName].CHECK_APP_VERSION &&
      (appVersion.major <
        ConstantMapping[clientName].MINIMUM_APP_VERSION_GIFTING.major ||
        (appVersion.major ==
          ConstantMapping[clientName].MINIMUM_APP_VERSION_GIFTING.major &&
          appVersion.minor <
            ConstantMapping[clientName].MINIMUM_APP_VERSION_GIFTING.minor))
    ) {
      setAnchorEl(codeRef.current);
      setPopoverMessage(
        ConstantMapping[clientName].APP_VERSION_FEATURE_ERROR_MESSAGE
      );
    } else {
      tealiumEventTagging();
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTimeout(() => setSelctedDialogOption(null), 100);
  };
  const codeRef = React.useRef(null);

  const hidePopoverMsg = () => {
    setTimeout(() => {
      handleClose();
    }, 1300);
  };

  const handleClick = () => {
    setAnchorEl(codeRef.current);
  };

  const initializeClipboard = () => {
    const currentClipboard = new ClipboardJS("#copy-button");
    //@ts-ignore
    currentClipboard.on("success", function (e) {
      setPopoverMessage("Copied!");
      hidePopoverMsg();
      e.clearSelection();
    });
    //@ts-ignore
    currentClipboard.on("error", function (e) {
      setPopoverMessage("Failed!");
      hidePopoverMsg();
      return false;
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const buttonId = open ? "simple-popover" : undefined;

  const goBack = () => {
    window.history.back();
  };

  // 19 digit Gift Card and 4 digit Pin + 20 digit Barcode
  const barcodeGiftCard = config => {
    const code = claimedOffer?.redemptionCode.split("|");
    const [giftCode, pin, redemptionCode] = [code[0], code[1], code[2]];
    return (
      <Card style={{ backgroundColor: "#ffffff", marginBottom: "30px" }}>
        <CardContent>
          <QRBarcode
            type={"Barcode"}
            value={redemptionCode}
            config={config}
            clientName={clientName}
          />
          <hr style={{ border: "1px solid #C0C0C0" }} />
          <div className={classes.barcodeGiftDetails}>
            <div className={classes.barcodeGift}>
              <span className={classes.giftHeader}>GIFT CARD NUMBER</span>
              <span>{giftCode}</span>
            </div>
            <div
              style={{ border: "1px solid #dcdcdc", marginTop: "-10px" }}
            ></div>
            <div className={classes.barcodeGift}>
              <span className={classes.giftHeader}>PIN</span>
              <span>{pin}</span>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  const renderRedemptionCode = (type: RedemptionCodeType) => {
    let config: any = {
      displayValue: true,
      width:
        claimedOffer?.redemptionCode && claimedOffer.redemptionCode.length > 10
          ? 1
          : 2,
      background: "transparent",
      format: ""
    };
    let isBarcode = false;
    switch (type) {
      case RedemptionCodeType.QR:
        return (
          <QRBarcode
            type={"QR"}
            value={claimedOffer?.redemptionCode}
            clientName={clientName}
          />
        );
      case RedemptionCodeType.BARCODE_EAN_128:
        config.format = "CODE128";
        config["ean128"] = true;
        isBarcode = true;
        break;
      case RedemptionCodeType.BARCODE_EAN_8:
        config.format = "EAN8";
        isBarcode = true;
        break;
      case RedemptionCodeType.BARCODE_EAN_13:
        config.format = "EAN13";
        isBarcode = true;
        break;
      case RedemptionCodeType.BARCODE_CODE_128:
        config.format = "CODE128";
        isBarcode = true;
        break;
      case RedemptionCodeType.BARCODE_GC_19:
        config.format = "CODE128";
        config.width = 2;
        return barcodeGiftCard(config);
      case RedemptionCodeType.ALPHANUMERIC:
        return (
          <div className={classes.codeContainer}>
            {claimedOffer?.redemptionCode &&
            claimedOffer?.redemptionCode.length ? (
              <Fragment>
                <h1
                  id="foo"
                  ref={codeRef}
                  className={classes.codeWrapper}
                  style={{
                    fontSize: `min(38px, ${
                      150 / claimedOffer.redemptionCode.length
                    }vw)`
                  }}
                >
                  {claimedOffer?.redemptionCode}
                </h1>
                <Button
                  id="copy-button"
                  color="primary"
                  onLoad={initializeClipboard}
                  onClick={handleClick}
                  className={classes.clipBoardButton}
                  data-clipboard-target="#foo"
                  endIcon={
                    <img
                      alt=""
                      src={ImagesMapping[clientName].copyIcon}
                      className={classes.buttonIcon}
                    />
                  }
                >
                  Copy to clipboard
                </Button>
              </Fragment>
            ) : (
              ""
            )}
          </div>
        );
      default:
        isBarcode = false;
    }
    if (isBarcode)
      return (
        <QRBarcode
          type={"Barcode"}
          value={claimedOffer?.redemptionCode}
          config={config}
          clientName={clientName}
        />
      );
  };

  const loadEvent = () => {
    const state = {
      onLoadParams: {
        show_view: false,
        event_name: "PageInteraction",
        event_action: "button",
        event_label: "Go to site",
        page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${currentOffer?.title}>${ConstantMapping[clientName].TEALIUM_PAGE_CLAIM}`,
        same_page_event: true,
        page_subsection: "offers"
      }
    };
    loadEvents(previousPages, { state });
  };

  const renderExternalSiteButton = (
    url: string | null,
    type: RedemptionCodeType
  ) => {
    let webUrl = url;
    if (type === RedemptionCodeType.URL) {
      webUrl = claimedOffer?.redemptionCode;
    }
    if (webUrl)
      return (
        <Button
          variant="contained"
          color="primary"
          href={"external:" + webUrl}
          target="_system"
          className={classes.buttonClass}
          onClick={loadEvent}
        >
          <Typography className={classes.buttonText}>Go to site</Typography>
        </Button>
      );
    else return null;
  };

  const renderShareButton = () => {
    let shareable = currentOffer?.shareable;
    if (shareable)
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.giveAGiftButton}
          startIcon={<img alt="" src={ImagesMapping[clientName].giftIcon} />}
          onClick={handleClickOpenDialog}
        >
          <Typography className={classes.buttonText}>Give a gift</Typography>
        </Button>
      );
  };

  const renderContent = (
    method: RedemptionMethod,
    code: RedemptionCodeType,
    url: string | null
  ) => {
    switch (method) {
      case RedemptionMethod.AUTOMATIC:
        return (
          <Button
            variant="contained"
            color="primary"
            className={classes.closeButton}
            onClick={goBack}
          >
            <Typography className={classes.buttonText}>Close</Typography>
          </Button>
        );
      case RedemptionMethod.ONLINE:
      case RedemptionMethod.INSTORE_OR_ONLINE:
        return (
          <>
            {renderRedemptionCode(code)}
            <div>
              {renderExternalSiteButton(url, code)}
              {renderShareButton()}
            </div>
          </>
        );
      case RedemptionMethod.INSTORE:
        return (
          <>
            {renderRedemptionCode(code)}
            {renderShareButton()}
          </>
        );
      default:
        return noCodeText;
    }
  };

  const giftSuccessCallback = (res: any) => {
    setIsGifted(true);
    try {
      let updatedOffers = props.offers;
      let updatedClaimedOffers = props.claimedOffers;
      updatedOffers[claimedOffer.offerId]["isShared"] = true;

      let newClaimedOffer = updatedClaimedOffers[id];
      let giftAttribute = {
        sharedOn: res?.sharedOn
      };

      if (newClaimedOffer?.attributes?.length)
        newClaimedOffer.attributes.push(giftAttribute);
      else newClaimedOffer["attributes"] = [giftAttribute];

      newClaimedOffer.source = "SHARING";
      updatedClaimedOffers[id] = newClaimedOffer;

      new Offers({
        id: "",
        ...updatedOffers
      }).$save();

      new ClaimedOffers({
        id: "",
        ...updatedClaimedOffers
      }).$save();
    } catch (error) {
      console.log("error in saving offer to redux");
    }
  };

  const renderNoCodes = () => {
    return (
      <div className={classes.noCode}>
        <Typography variant={"h4"}>{noCodeText}</Typography>
      </div>
    );
  };

  const redirectToHome = () => {
    history.push("/home");
  };
  if (!isGifted)
    return (
      <>
        <Header
          headerText="Back"
          svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={goBack}
          isHeaderInverted={isPrizeDraw || isVisitToWin}
          onLogoSelection={redirectToHome}
          isScrollHeader={true}
        />
        <div
          className={
            isPrizeDraw || isVisitToWin
              ? classes.containerPrizeDraw
              : classes.container
          }
        >
          {/* <div
          className={
            isPrizeDraw
              ? classes.headerContainerPrizeDraw
              : classes.headerContainer
          }
        >
        </div> */}
          {currentOffer && claimedOffer ? (
            currentOffer.offerType === OfferType.REWARD ||
            currentOffer.offerType === OfferType.GOODWILLREWARD ||
            currentOffer.offerType === OfferType.INSTANT_WIN ||
            currentOffer.offerType === OfferType.LUCKY_DIP ||
            currentOffer.offerType === OfferType.SPIN_TO_WIN ? (
              <>
                <Popover
                  id={buttonId}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                >
                  <Typography
                    style={{
                      padding: "10px 20px",
                      background: "#424242",
                      color: "#FFF"
                    }}
                  >
                    {popoverMessage}
                  </Typography>
                </Popover>
                {isGifted ? null : (
                  <>
                    <div
                      className={
                        height < 30
                          ? classes.countdownContainer
                          : classes.countdownWrapper
                      }
                      style={
                        currentOfferIsExpiredCode || isExpiryModelNone
                          ? { borderBottomColor: "transparent" }
                          : undefined
                      }
                    >
                      <Typography
                        variant={"h3"}
                        id="coundownTitle"
                        className={classes.coundownTitle}
                      >
                        {currentOffer.title}
                      </Typography>
                      {showTimer &&
                      !currentOfferIsExpiredCode &&
                      !isExpiryModelNone ? (
                        <div className={classes.countdownTimer}>
                          <CountDownTimer
                            remainingTicks={computeTicks(
                              claimedOffer?.expiryDateTime
                            )}
                            headerInfoText={"Code expires in:"}
                            style={{
                              background: "#000000bf",
                              color: "#FFFFFF",
                              height: "70px",
                              padding: "9px 5vw",
                              boxSizing: "border-box",
                              fontSize: "12px"
                            }}
                            styleOptions={{
                              headerInfoStyle: {},
                              timerNumbersStyle: {
                                fontFamily: "VodafoneRgBold"
                              }
                            }}
                            options={{
                              onComplete: delta => {
                                //this function is called when timer counter is complete
                                //signature - {"total":0,"days":0,"hours":0,"minutes":0,"seconds":0,"milliseconds":0,"completed":true}
                                if (delta?.completed) {
                                  try {
                                    updateClaimedOffersFromApi(
                                      props.offers,
                                      currentOffer
                                    );
                                  } catch (error: any) {
                                    if (error?.networkError) {
                                      history.push("/error-500");
                                    } else {
                                      showSnackBar();
                                    }
                                  }
                                }
                              }
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                    {!currentOfferIsExpiredCode || isExpiryModelNone ? (
                      <div
                        className={classes.redemptionContainer}
                        id="redemption-container"
                      >
                        {redemptionDetails
                          ? redemptionDetails.map(
                              (details: RedemptionDetail, index: number) => {
                                return (
                                  <Fragment key={index.toString()}>
                                    <RedemptionGuidelines
                                      guidelines={details.guidelines}
                                    />
                                    {claimedOffer?.prizeType ===
                                    ConstantMapping[getClientName()]
                                      .GRAND_PRIZE ? null : (
                                      <div className={classes.redemptiondata}>
                                        {renderContent(
                                          details.redemptionMethod,
                                          details.redemptionType,
                                          details.url
                                        )}
                                      </div>
                                    )}
                                  </Fragment>
                                );
                              }
                            )
                          : null}

                        {claimedOffer?.prizeEntries &&
                        claimedOffer?.prizeType ===
                          ConstantMapping[getClientName()].GRAND_PRIZE ? (
                          <p className={classes.grandPrizeWinnerLabel}>
                            Number of entries won: {claimedOffer?.prizeEntries}
                          </p>
                        ) : null}
                        <GiftingDialog
                          claimId={id}
                          title={currentOffer.titleShort}
                          openDialog={openDialog}
                          selctedDialogOption={selctedDialogOption}
                          handleCloseDialog={handleCloseDialog}
                          setSelctedDialogOption={text => {
                            setOpenDialog(false);
                            setTimeout(() => {
                              setSelctedDialogOption(text);
                              setOpenDialog(true);
                            }, 250);
                          }}
                          successCallback={giftSuccessCallback}
                          callToActionDetails={
                            currentOffer?.callToActionDetails
                          }
                          clientName={clientName}
                        />
                      </div>
                    ) : (
                      renderNoCodes()
                    )}
                  </>
                )}
              </>
            ) : isPrizeDraw || isVisitToWin ? (
              <PrizeDrawRedemption
                redemptionDetails={redemptionDetails}
                images={currentOffer?.images}
                onClose={goBack}
                offerData={currentOffer}
              />
            ) : (
              renderNoCodes()
            )
          ) : (
            renderNoCodes()
          )}
        </div>
      </>
    );
  else
    return (
      <GiftedScreen
        clientName={clientName}
        goBack={goBack}
        offerId={claimedOffer?.offerId}
      />
    );
};

const mapStateToProps = (state: any) => {
  let offers = Offers.getInsatnce("", state).props;
  let claimedOffers = ClaimedOffers.getInsatnce("", state)?.props;
  return {
    offers,
    claimedOffers
  };
};
export default connect(mapStateToProps)(RedemptionCode);
