import { BaseModel } from "ReduxStore/index";


export interface IAnnouncement {
    title: string;
    announcementId: number ;
    description:string;
    ctaRedirect:string;
    ctaColor:string;
    offerId:number;
    ctaText:string;
    backgroundImage:string;
    iconImage:string;
    bannerImage:string;
    showOnEveryVisit: boolean
}

export default class Announcement extends BaseModel<IAnnouncement> {
  static resource = "Announcement";
}
