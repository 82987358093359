const VodafoneImages = Object.freeze({
  layoutBackgroundImg: require(`../vodafone/Assets/Images/background.jpg`),
  splashScreenLogo: require(`../vodafone/Assets/Images/loaderWhite.svg`)
    .default,
  landingScreenLogo: require(`../vodafone/Assets/Images/logo@3x.png`),
  myProfileBgImg: require(`../vodafone/Assets/Images/My-Profile-Landing-Page.jpg`),
  smsPermissionHeroImg: require(`../vodafone/Assets/Images/onboardingPermissionsSms.png`),
  trafficPermissionHeroImg: require(`../vodafone/Assets/Images/backphone3.png`),
  smsPermissionImg: require(`../vodafone/Assets/Images/backphone.png`),
  trafficImg: require(`../vodafone/Assets/Images/traffic.svg`).default,
  trafficCircleImg: require(`../vodafone/Assets/Images/circle_b.png`),
  trafficPermissionNewImage: require(`../vodafone/Assets/Images/network.jpg`),
  SummaryHeroImg: require(`../vodafone/Assets/Images/backphone3.png`),
  summaryCard1: require(`../vodafone/Assets/Images/card1.png`),
  summaryCard2: require(`../vodafone/Assets/Images/card2.png`),
  summaryCard3: require(`../vodafone/Assets/Images/card3.png`),
  summaryCard4: require(`../vodafone/Assets/Images/card4.png`),
  profilPermissionImage: require(`../vodafone/Assets/Images/onboardingPermissionsProfilingR02Assets@2x.png`),
  clientLogoPrimary: require(`../vodafone/Assets/Images/client-logo-primary.png`),
  clientLogoSecondary: require(`../vodafone/Assets/Images/client-logo-secondary.png`),
  clientErrorPageLogo: require(`../vodafone/Assets/Images/client-error-page-logo.png`),
  copyIcon: require(`../vodafone/Assets/Images/group9.png`),
  giftIcon: require(`../vodafone/Assets/Images/iconGiftUnopenedSmallWhite.png`),
  emailIcon: require(`../vodafone/Assets/Images/group7.png`),
  whatsappIcon: require(`../vodafone/Assets/Images/group6.png`),
  textMessageIcon: require(`../vodafone/Assets/Images/speech.png`),
  giftRedIcon: require(`../vodafone/Assets/Images/iconGiftUnopenedRed.png`),
  arrowLeftIcon: require(`../vodafone/Assets/Images/arrowLeftSvg_2@2x.png`),
  giftIconAll: require(`../vodafone/Assets/Images/allRewards@2x.png`),
  loaderSvg: require(`../vodafone/Assets/Images/loader.svg`).default,
  timerSvg: require(`../vodafone/Assets/Images/timer.svg`).default,
  iwIntroGif: require(`../vodafone/Assets/Images/instant-win-intro.jpg`),
  iwWinGif: require(`../vodafone/Assets/Images/instant-win-winner.jpg`),
  iwLooseGif: require(`../vodafone/Assets/Images/instant-win-loser.jpg`),
  iwPopBalloonGif: require(`../vodafone/Assets/Images/centre-balloon-bright-single.gif`),
  homeScreenPrimerImage: require(`../vodafone/Assets/Images/homeScreenFilters@2x.png`),
  homeScreenChristmas: require(`../vodafone/Assets/Images/home_primer_christmas.jpg`),
  iconCloseWhite: require(`../vodafone/Assets/Images/icon-close-white.svg`)
    .default,
  iconGiftOpenedWhite: require(`../vodafone/Assets/Images/iconGiftOpenedWhite@2x.png`),
  iconLock: require(`../vodafone/Assets/Images/lock.png`),
  iconEmptyBox: require(`../vodafone/Assets/Images/empty-box.svg`).default,
  iconEmptyBoxBlack: require(`../vodafone/Assets/Images/empty-box-black.svg`)
    .default,
  pinIcon: require(`../vodafone/Assets/Images/pin.png`),
  popupIcon: require(`../vodafone/Assets/Images/popup.png`),
  redpinIcon: require(`../vodafone/Assets/Images/redpin.png`),
  locationPermissionHeroImg: require(`../vodafone/Assets/Images/backphone2.png`),
  clientFavicon: require(`../vodafone/Assets/Images/favicon.ico`),
  hairdryerIcon: require(`../vodafone/Assets/Images/hairdryer.png`),
  notaboneIcon: require(`../vodafone/Assets/Images/notabone.png`),
  reallyIcon: require(`../vodafone/Assets/Images/really.png`),
  notificationImg: require(`../vodafone/Assets/Images/notification.png`),
  noSummaryCard1: require(`../vodafone/Assets/Images/card1_e.png`),
  noSummaryCard2: require(`../vodafone/Assets/Images/card2_e.png`),
  noSummaryCard3: require(`../vodafone/Assets/Images/card3_e.png`),
  noSummaryCard4: require(`../vodafone/Assets/Images/card4_e.png`),
  chatOfferIcon: require(`../vodafone/Assets/Images/allRewardsChat@2x.png`),
  chatFaqIcon: require(`../vodafone/Assets/Images/inAppLink@2x.png`),
  chatExternalLinkIcon: require(`../vodafone/Assets/Images/externalLink@2x.png`),
  eatLocalBackground: require(`../vodafone/Assets/Images/eatLocalOffer.jpg`),
  eatLocalGourmetImage: require(`../vodafone/Assets/Membership/GS_black_MAINLOGO.png`),
  membershipStartImage: require(`../vodafone/Assets/Membership/membershipstartImg.png`),
  membershipBgImage: require(`../vodafone/Assets/Membership/membershipBg.png`),
  membershipBackgroundImg: require(`../vodafone/Assets/Membership/membershipBackgroundImg.png`),
  useCardBgImage: require(`../vodafone/Assets/Membership/usecardbg.png`),
  membershipWlBgImage: require(`../vodafone/Assets/Membership/welcomeBg.png`),
  membershipSearchImage: require(`../vodafone/Assets/Membership/search.png`),
  membershipRewardImage: require(`../vodafone/Assets/Membership/rewards.png`),
  membershipImage: require(`../vodafone/Assets/Membership/membership.png`),
  eatlocalLogo: require(`../vodafone/Assets/Images/eatLocalLogo.png`),
  waitLoaderSvg: require(`../vodafone/Assets/Images/wait_loader.svg`).default,
  christmasSpecialBackgroundImg: require(`../vodafone/Assets/ChristmasImages/christmasbg.jpg`),
  vmr_loaderwhite: require(`../vodafone/Assets/Images/vmr_loaderwhite.svg`)
    .default,
  tickIcon: require(`../vodafone/Assets/Images/tick.png`),
  vmrLite_AppIntro_First: require(`../vodafone/Assets/Images/vmrLite_AppIntro_1.png`),
  vmrLite_AppIntro_Second: require(`../vodafone/Assets/Images/vmrLite_AppIntro_2.png`),
  topUpTo_Unlock: require(`../vodafone/Assets/Images/topUpIcon.png`),
  vmrLite_RedIcon: require(`../vodafone/Assets/Images/giftedRed.png`),
  luckydip_IntroScreen: require(`../vodafone/Assets/Images/luckDipIntro_Screen.jpg`),
  claw_IntroScreen: require(`../vodafone/Assets/Images/claw-intro-screen.gif`),
  conveyorbelt_IntroScreen: require(`../vodafone/Assets/Images/conveyor-belt-intro-screen.gif`),
  PicktoWin_background: require(`../vodafone/Assets/Images/PicktoWin-choose-background.gif`),
  luckydip_CongratsScreen: require(`../vodafone/Assets/Images/PicktoWin-Winning-Congrats.gif`),
  picktoWin_InterimStatic: require(`../vodafone/Assets/Images/PicktoWin-Choosing-Winning-InterimStatic.jpg`),
  visit_To_Win_Bg: require(`../vodafone/Assets/Images/V2W-home-background.jpg`),
  visit_To_Win_Logo: require(`../vodafone/Assets/Images/V2W-logo.png`),
  visit_To_Win_Animated_Heart: require(`../vodafone/Assets/Images/V2W-animated-heart.gif`),
  visit_To_Win_FullScreen_Heart: require(`../vodafone/Assets/Images/V2W-fullscreen-heart.gif`),
  visit_To_Win_HalfScreen_Overlay: require(`../vodafone/Assets/Images/V2Win-partial-overlay.gif`),
  luckydip_New_Intro_solo: require(`../vodafone/Assets/Images/identity-overlay-solo.png`),
  iwXmasIntroBg: require(`../vodafone/Assets/Images/iw-Xmas-bg.jpg`),
  iwXmasIntroSnowflakes: require(`../vodafone/Assets/Images/iw-Xmas-Intro-Snowflakes.gif`),
  iwXmasWinGif: require(`../vodafone/Assets/Images/iw-Xmas-Winners.gif`),
  iwXmasLooseGif: require(`../vodafone/Assets/Images/iw-Xmas-Losers.gif`),
  christmasLoadingScreen: require(`../vodafone/Assets/Images/christmas_loading_screen.gif`),
  iwVisit_To_Win_HalfScreen_Overlay: require(`../vodafone/Assets/Images/iw-V2Win-partial-overlay.gif`),
  iwVisit_To_Win_FullScreen_Heart: require(`../vodafone/Assets/Images/iw-V2W-fullscreen-heart.gif`),
  iwVisit_To_Win_Snowflakes_Heart: require(`../vodafone/Assets/Images/iw-V2W-fullscreen-snowflakes-heart.gif`),
  iwluckydip_IntroScreen: require(`../vodafone/Assets/Images/iw-luckDipIntro_Screen.gif`),
  iwPicktoWin_background: require(`../vodafone/Assets/Images/iw-PicktoWin-choose-background.gif`),
  iwPicktoWin_backgroundP2: require(`../vodafone/Assets/Images/iw-PicktoWin-choose-background2.gif`),
  iwluckydip_CongratsScreen: require(`../vodafone/Assets/Images/iw-PicktoWin-Winning-Congrats.gif`),
  iwPicktoWin_InterimStatic: require(`../vodafone/Assets/Images/iw-PicktoWin-Choosing-Winning-InterimStatic.jpg`),
  myprofile_converged_user: require(`../vodafone/Assets/Images/VFTogetherRewards-rounded.png`),
  vfWhiteIcon: require(`../vodafone/Assets/Images/VMR-icon.png`),
  vfTogetherRewaredRoundIcon: require(`../vodafone/Assets/Images/VFTogetherRewards-roundel.png`),
  vfGradientBackGroundImage: require(`../vodafone/Assets/Images/VMR-gradient-background.jpg`),
  convergedClaimImage: require(`../vodafone/Assets/Images/converged-offer-claim.png`),
  coffeeClubLogoImage: require(`../vodafone/Assets/Images/coffee_club_logo.png`),
  newOnboardingGradientBg: require(`../vodafone/Assets/Images/onboarding/VMR_Onboarding_BG.png`),
  newOnboardingStartImage: require(`../vodafone/Assets/Images/onboarding/onboarding_start.png`),
  newOnboardingEndImage: require(`../vodafone/Assets/Images/onboarding/onboarding_end.png`),
  newOnboardingNetworkImage: require(`../vodafone/Assets/Images/onboarding/onboarding_network.png`),
  newOnboardingNotificationImage: require(`../vodafone/Assets/Images/onboarding/onboarding_notification.png`),
  newOnboardingProfilingImage: require(`../vodafone/Assets/Images/onboarding/onboarding_profiling.png`),
  newOnboardingLocationImage: require(`../vodafone/Assets/Images/onboarding/onboarding_location.png`),
  newOnboardingVodafoneIcon: require(`../vodafone/Assets/Images/onboarding/onboarding_white_icon.png`),
  newOnboardingVodafoneTrafficLocationImage: require(`../vodafone/Assets/Images/onboarding_new_location_traffic_upperimg.png`),
  rightArrow: require(`../vodafone/Assets/RestoLocalBlendImages/arrow-right.png`),
  availabilityFriday: require(`../vodafone/Assets/RestoLocalBlendImages/ListViewIcons/icon-no-availability-FRI-grey.png`),
  availabilitySat: require(`../vodafone/Assets/RestoLocalBlendImages/ListViewIcons/icon-no-availability-SAT-grey.png`),
  availabilitySun: require(`../vodafone/Assets/RestoLocalBlendImages/ListViewIcons/icon-no-availability-SUN-grey.png`),
  noAvailability: require(`../vodafone/Assets/RestoLocalBlendImages/ListViewIcons/icon-no-availability-grey.png`),
  availability: require(`../vodafone/Assets/RestoLocalBlendImages/ListViewIcons/availability.png`),
  eatLocalImg: require(`../vodafone/Assets/Images/FAQ-Asset/FAQ-image 1.png`),
  generalImg: require(`../vodafone/Assets/Images/FAQ-Asset/FAQ-image 2.png`),
  paygImg: require(`../vodafone/Assets/Images/FAQ-Asset/FAQ-image 3.png`),
  personalDetailsImg: require(`../vodafone/Assets/Images/FAQ-Asset/FAQ-image 4.png`),
  rewardsImg: require(`../vodafone/Assets/Images/FAQ-Asset/FAQ-image 5.png`),
  subscriptionsImg: require(`../vodafone/Assets/Images/FAQ-Asset/FAQ-image 6.png`),
  vfTogetherImg: require(`../vodafone/Assets/Images/FAQ-Asset/FAQ-image 7.png`),
  iwIntroNew: require(`../vodafone/Assets/Images/instantWinIntroNew.png`),
  iwWinNew: require(`../vodafone/Assets/Images/instant-win-winner-new.png`),
  iwLoseNew: require(`../vodafone/Assets/Images/instant-win-loser-new.png`),
  loadingScreenBg: require(`../vodafone/Assets/Images/VMR_Loading_Screen_new.png`),
  giftIconSparks: require(`../vodafone/Assets/Images/Icon_Gift_Unopened_sparks.png`),
  shareChatIcon: require(`../vodafone/Assets/Images/ReferalAssets/shareReferalChatIcon.png`),
  shareEmailIcon: require(`../vodafone/Assets/Images/ReferalAssets/shareReferalEmailIcon.png`),
  shareCallIcon: require(`../vodafone/Assets/Images/ReferalAssets/shareReferalCallIcon.png`),
  referalHappyfrds: require(`../vodafone/Assets/Images/ReferalAssets/Happy-Multi-ethnic-Friends-Outdoors.png`),
  stw_logos: require(`../vodafone/Assets/Images/STW_Logos.png`),
});

export default VodafoneImages;
