import FavouriteOffers, {
  IFavouriteOffers
} from "../Models/ReduxModels/FavouriteOffersModel";
import Offers, {
  ImageClassifier,
  Offer,
  BadgeType,
  ClaimLimitDetails,
  IOffers
} from "../Models/ReduxModels/OffersModel";
import { removeFavouriteOffers } from "../Services/RemoveFavouriteService";
import { addFavouriteOffers } from "../Services/AddFavouriteService";
import ClaimedOffers, {
  ClaimedOffer,
  IClaimedOffers
} from "Models/ReduxModels/ClaimedOffersModel";
import { isAfter } from "date-fns";
// import { iconLock, iconEmptyBox } from "Constants/Require/Require";
import { OfferType } from "Models/Interfaces/OfferType";
import AppConfig, { AppSectionConfig } from "Models/ReduxModels/AppConfig";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { ThemeMapping } from "Configurations/ThemeMapping";
import { IBadgeProps } from "gce-cxinteracts-ui-components-library";
import {
  isoToLocalDateConversion,
  getImageDetails,
  getCurrentTime,
  getClientName,
  getValueFromHashMapArray
} from "./GeneralUtils";
import moment from "moment";
import { OfferIds } from "Models/ReduxModels/SortedFavouriteOfferIds";
import { Actions } from "Models/Interfaces/Actions";
import { getClaimedOffers } from "Services/ClaimedOffersService";
import customBadgeIconImage from "Configurations/Clients/vodafone/Assets/Images/custom_logo_lozenge.svg";
import UserDetails from "../Models/ReduxModels/UserDetails";
// import { topUpTo_Unlock } from "../Constants/Require/Require";
import { ImagesMapping } from "Configurations/ImagesMapping";

export const checkVMRLiteUser = () => {
  const userDetails = UserDetails.getInsatnce("")?.props;
  if (userDetails && userDetails?.appAccessLevel === "PAYG_LITE") {
    return true;
  }
  return false;
};

export const checkPayGplusUser = () => {
  const userDetails = UserDetails.getInsatnce("")?.props;
  if (userDetails && userDetails?.appAccessLevel === "PAYG_PLUS_LITE") {
    return true;
  }
  return false;
};

export const isFavoriteOffer = (offerId: string | number | undefined) => {
  if (!offerId) {
    return false;
  }
  const favorites: IFavouriteOffers = FavouriteOffers.getInsatnce("")?.props;
  if (favorites && favorites[offerId]?.createdOn) {
    return true;
  }
  return false;
};

export const getOfferImage = (imageType: ImageClassifier, images: any) => {
  if (!images || !imageType) {
    return { url: "", altText: "" }; // return default image
  }
  return {
    url: images[imageType]?.url,
    altText: images[imageType]?.altText ?? ""
  };
};

export const addRemoveFavouriteOffers = (
  offerId: string | number | undefined,
  favorites: IFavouriteOffers,
  favouriteOfferIds: OfferIds
) => {
  const isOfferFav = isFavoriteOffer(offerId);
  let variable = { offerId: offerId };
  if (isOfferFav) {
    return removeFavouriteOffers(variable, favorites, favouriteOfferIds);
  } else {
    return addFavouriteOffers(variable, favorites, favouriteOfferIds);
  }
};

export const getLatestClaimDetailsByClaimId = (
  claimIds: number[],
  claimedOffers: IClaimedOffers
) => {
  if (!claimIds || !claimIds.length || !claimedOffers) {
    return null;
  }
  let compareDate = new Date(10 - 10 - 2005); // random old date
  let latestId = claimIds[0];
  claimIds.forEach((id: number, index: number) => {
    if (claimedOffers[id] && claimedOffers[id].claimDateTime) {
      if (isAfter(claimedOffers[id].claimDateTime, compareDate)) {
        compareDate = claimedOffers[id].claimDateTime;
        latestId = id;
      }
    }
  });
  return claimedOffers[latestId];
};

export const getOverlayIcon = (offer: Offer | undefined) => {
  const clientName = getClientName();
  if (!offer) {
    return getImageDetails();
  }
  const { user, total, totalPerPeriod, notEnoughCodes, userPerPeriod } =
    offer.claimLimitDetails;
  if (
    !isOfferComingSoon(offer.startDateTime) &&
    !userPerPeriod &&
    !user &&
    (total || totalPerPeriod || notEnoughCodes)
  ) {
    return getImageDetails(ImagesMapping[clientName].iconEmptyBox, "");
  }
  return getImageDetails(ImagesMapping[clientName].iconLock, "Locked Icon");
};

export const isObjectEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const getCustomCtaDetails = (offer: any, ctaType: string) => {
  let ctaDetails = offer?.callToActionDetails;
  let articleObject = (ctaDetails || []).filter((item: any) => {
    return item.key === ctaType;
  });
  return articleObject?.[0];
};

const computeHappensIn = (date: Date) => {
  if (!date) {
    return "";
  }
  let clientName = getClientName();
  let minInterval = moment(date).diff(moment(getCurrentTime()), "minutes");
  let hoursInterval = moment(date).diff(moment(getCurrentTime()), "hours");
  let daysInterval = moment(date).diff(moment(getCurrentTime()), "days");

  if (minInterval === 0) {
    return ConstantMapping[clientName].BADGE_HAPPENSIN_MOMENT;
  }
  if (minInterval === 1) {
    return ConstantMapping[clientName].BADGE_HAPPENSIN_MIN;
  }
  if (hoursInterval === 0) {
    return `in ${minInterval} mins`;
  }
  if (hoursInterval === 1) {
    return ConstantMapping[clientName].BADGE_HAPPENSIN_HOUR;
  }
  if (hoursInterval < 24) {
    return `in ${hoursInterval} hours`;
  }
  if (daysInterval === 1) {
    return ConstantMapping[clientName].BADGE_HAPPENSIN_TOMORROW;
  }
  return `in ${daysInterval} days`;
};

const getDefaultThemeBadge = (text: string) => {
  let clientName = getClientName();
  const theme = ThemeMapping[clientName];
  const badgeDetails: IBadgeProps = {
    badgeStyle: {
      boxShadow: theme.shadows[0],
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main
    },
    badgeType: "default",
    badgeText: text
  };
  return badgeDetails;
};

const getOverlayStripBadge = (
  text: string,
  showIcon: boolean = false,
  showImage: string = "",
  offerType: string = ""
) => {
  let clientName = getClientName();
  const theme = ThemeMapping[clientName];
  const LUCKY_DIP = "LUCKY_DIP";
  const badgeDetails: IBadgeProps = {
    badgeType: BadgeType[BadgeType.OVERLAYSTRIP].toString(),
    badgeStyle: {
      color: clientName === "vodafone" ? "" : theme.palette.common.white,
      backgroundColor:
        clientName === "vodafone"
          ? offerType === LUCKY_DIP
            ? theme.palette.primary.main
            : theme.palette.secondary.dark
          : theme.palette.primary.main
    },
    badgeText: text,
    showCustomBadgeIcon: showIcon,
    customBadgeIcon: showImage
  };
  return badgeDetails;
};

export const isOfferComingSoon = (startDateTime: Date) => {
  return moment(startDateTime).isAfter(getCurrentTime());
};

export const isOfferLocked = (offer: Offer, checkCSD: boolean = true) => {
  let claimsLimit: ClaimLimitDetails = {
    notEnoughCodes: offer.claimLimitDetails.notEnoughCodes,
    total: offer.claimLimitDetails.total,
    totalPerPeriod: offer.claimLimitDetails.totalPerPeriod,
    user: offer.claimLimitDetails.user,
    userPerPeriod: offer.claimLimitDetails.userPerPeriod
  };

  let comingSoonOfferDetails = checkCSD
    ? isObjectEmpty(offer?.comingSoonDetails?.images) || null
    : true;
  let isOfferComingSoon =
    moment(offer.startDateTime).isAfter(getCurrentTime()) &&
    comingSoonOfferDetails;
  let isLocked: boolean =
    isOfferComingSoon ||
    (claimsLimit.userPerPeriod && claimsLimit.userPerPeriod?.length > 0) ||
    claimsLimit.user ||
    claimsLimit.totalPerPeriod ||
    claimsLimit.total ||
    (offer.claimConditionsFailed && offer.claimConditionsFailed.length > 0) ||
    claimsLimit.notEnoughCodes;
  return !!isLocked;
};

export const isOfferCodeValid = (offer: Offer, claimedId?: number) => {
  let claimedOffers = ClaimedOffers.getInsatnce("").props;
  let claimedIds = offer?.claimedIds;
  let claimedDetails: ClaimedOffer | null = null;
  if (claimedId) {
    claimedDetails = claimedOffers[claimedId];
  } else {
    claimedDetails = getLatestClaimDetailsByClaimId(claimedIds, claimedOffers);
  }
  if (!claimedDetails) {
    return false;
  }
  let parsedOfferType = (<any>OfferType)[offer.offerType];
  let isNoExpiry = claimedDetails && !claimedDetails.expiryDateTime;
  let isFutureExpiry =
    claimedDetails &&
    claimedDetails.expiryDateTime &&
    moment(claimedDetails.expiryDateTime).isAfter(getCurrentTime());

  if (
    isFutureExpiry ||
    (parsedOfferType === OfferType[OfferType.PRIZEDRAW] &&
      typeof claimedDetails.claimDateTime == "object") ||
    isNoExpiry
  ) {
    return true;
  }
  return false;
};

export const isOfferShared = (offer: Offer, claimedId?: number) => {
  let claimedOffers = ClaimedOffers.getInsatnce("").props;
  let claimedIds = offer.claimedIds;
  let claimedDetails: ClaimedOffer | null = null;
  if (claimedId) {
    claimedDetails = claimedOffers[claimedId];
  } else {
    claimedDetails = getLatestClaimDetailsByClaimId(claimedIds, claimedOffers);
  }
  let isShared = false;
  if (claimedDetails && claimedDetails.source === "SHARING") {
    isShared = true;
  }
  return isShared;
};

export const isOfferCodeExpired = (offer: Offer, claimedId?: number) => {
  let claimedOffers = ClaimedOffers.getInsatnce("").props;
  let claimedIds = offer?.claimedIds;
  let claimedDetails: ClaimedOffer | null = null;
  if (claimedId) {
    claimedDetails = claimedOffers[claimedId];
  } else {
    claimedDetails = getLatestClaimDetailsByClaimId(claimedIds, claimedOffers);
  }
  let isExpiredCode = false;
  if (
    !isOfferCodeValid(offer, claimedId) &&
    claimedDetails &&
    claimedDetails.expiryDateTime &&
    moment(getCurrentTime()).isAfter(claimedDetails.expiryDateTime)
  ) {
    isExpiredCode = true;
  }

  return isExpiredCode;
};

export const isOfferReclaimable = (offer: Offer, claimedId?: number) => {
  if (offer?.offerType === OfferType.SPIN_TO_WIN) {
    return !isOfferLocked(offer);
  }
  return !isOfferCodeValid(offer, claimedId) && !isOfferLocked(offer);
};

export const checkClaimedDetailsForLiteUser = (
  offer: Offer,
  claimedId?: number
) => {
  let claimedOffers = ClaimedOffers.getInsatnce("").props;
  let claimedDetails: ClaimedOffer | null = null;
  let claimedIds = offer.claimedIds;
  if (claimedId) {
    claimedDetails = claimedOffers[claimedId];
  } else {
    claimedDetails = getLatestClaimDetailsByClaimId(claimedIds, claimedOffers);
  }
  if (offer.offerType === OfferType.BANNER) {
    if (!offer.giveToLiteUser && !isOfferCodeExpired(offer) && claimedDetails) {
      return true;
    } else if (!offer.giveToLiteUser) {
      return false;
    } else {
      return true;
    }
  } else if (!offer.giveToLiteUser) {
    return false;
  } else {
    return true;
  }
};

export const getOfferBadgeDetails = (
  offer: Offer | undefined,
  size: "small" | "medium" | "large",
  claimedId?: number,
  showEmailPopup?: any,
  isFromMyCode?: boolean
) => {
  if (!offer) {
    return undefined;
  }
  const isLocked = isOfferLocked(offer);
  const isComingSoon = isOfferComingSoon(offer.startDateTime);
  let clientName = getClientName();
  let emailCapture = offer?.redemptionDetails?.[0]?.captureEmail || false;
  let isCustomVendor = ["Coffee Club", "Eat Local"].includes(
    offer?.addType || ""
  );
  // Eat Local/Coffee Club Membership is expired then show Membership Expired Badge
  if (
    offer.offerType === OfferType.BANNER &&
    isCustomVendor &&
    isOfferCodeExpired(offer)
  ) {
    return getOverlayStripBadge(
      ConstantMapping[clientName].BADGE_EATLOCAL_EXPIRED_CODE
    );
  }

  // Check If the converged reward offer is claimed, if it is then show below badge
  if (
    offer?.isConvergedOffer &&
    offer?.offerType === OfferType.REWARD &&
    retireveBannerDetails(offer?.additionalDetails, "allowConvergedClaim") ===
    "false"
  ) {
    return getOverlayStripBadge(
      ConstantMapping[clientName].BADGE_REWARD_CLAIMED
    );
  }
  // Check If the user has not top up of 10 pound from last 6 week
  if (
    checkVMRLiteUser() &&
    !checkClaimedDetailsForLiteUser(offer) &&
    !isLocked &&
    !isComingSoon &&
    !offer.claimLimitDetails.userPerPeriod
  ) {
    return getOverlayStripBadge(
      ConstantMapping[clientName].VMRLite_TOPUP_RECHARGE,
      true,
      ImagesMapping[clientName].topUpTo_Unlock
    );
  }

  // Check If the user has not top up of 10 pound from last 6 week PAYGPLUS user
  if (
    checkPayGplusUser() &&
    !checkClaimedDetailsForLiteUser(offer) &&
    !isLocked &&
    !isComingSoon &&
    !offer.claimLimitDetails.userPerPeriod
  ) {
    return getOverlayStripBadge(
      ConstantMapping[clientName].PAYGPLUS_UPGRADE_TOPUP,
      true,
      ImagesMapping[clientName].topUpTo_Unlock
    );
  }

  if (
    offer.offerType === OfferType.INSTANT_WIN &&
    (size === "small" ||
      (size === "medium" && !offer.instantWinResult) ||
      (size === "large" && !offer.instantWinResult))
  ) {
    if (isComingSoon) {
      return getDefaultThemeBadge(
        `Offer unlocks ${computeHappensIn(offer.startDateTime)}`
      );
    } else if (offer.instantWinResult && offer.instantWinResult === "lose") {
      return getOverlayStripBadge("Better luck next time!", false, "");
    } else if (offer.instantWinResult && offer.instantWinResult === "win") {
      return getOverlayStripBadge(
        ConstantMapping[clientName].LD_CONGRATS_BADGE,
        false,
        "",
        "LUCKY_DIP"
      );
    } else if (offer.teaser) {
      return getBadgeDetailsFromConfig(offer.teaser);
    } else {
      return getDefaultThemeBadge(ConstantMapping[clientName].BADGE_IW);
    }
  } else if (offer.offerType === OfferType.PRIZEDRAW) {
    let badgeDetails: IBadgeProps | undefined;

    if (isComingSoon) {
      return getDefaultThemeBadge(
        `Offer unlocks ${computeHappensIn(offer.startDateTime)}`
      );
    }
    if (offer.teaser) {
      badgeDetails = getBadgeDetailsFromConfig(offer.teaser);
    } else {
      badgeDetails = getDefaultThemeBadge("Prize draw");
    }

    if (size === "small" || size === "medium") {
      return badgeDetails;
    }
    if (size === "large" && (!offer.claimedIds || !offer.claimedIds.length)) {
      return badgeDetails;
    }
  } else if (offer.shareable && isComingSoon) {
    return getDefaultThemeBadge(
      `Offer unlocks ${computeHappensIn(offer.startDateTime)}`
    );
  } else if (offer.shareable && !isLocked && !isOfferShared(offer, claimedId)) {
    if (offer.teaser) {
      return getBadgeDetailsFromConfig(offer.teaser);
    } else {
      return getDefaultThemeBadge(ConstantMapping[clientName].BADGE_GIFT);
    }
  } else if (
    !isOfferCodeValid(offer, claimedId) ||
    (offer?.offerType === OfferType.SPIN_TO_WIN && !isFromMyCode)
  ) {
    if (isComingSoon) {
      return getDefaultThemeBadge(
        `Offer unlocks ${computeHappensIn(offer.startDateTime)}`
      );
    } else if (
      offer.claimLimitDetails.userPerPeriod &&
      offer.claimLimitDetails.userPerPeriod.length
    ) {
      if (offer?.offerType === OfferType.SPIN_TO_WIN)
        return getDefaultThemeBadge(
          `Spin again ${computeHappensIn(
            isoToLocalDateConversion(offer.claimLimitDetails.userPerPeriod)
          )}`
        );
      else
        return getDefaultThemeBadge(
          `Offer unlocks ${computeHappensIn(
            isoToLocalDateConversion(offer.claimLimitDetails.userPerPeriod)
          )}`
        );
    }
    // Gray badges start
    else if (
      isLocked &&
      !isComingSoon &&
      !offer.claimLimitDetails.userPerPeriod
    ) {
      if (
        (offer.claimLimitDetails.user || offer.claimLimitDetails.total) &&
        offer.claimedIds &&
        offer.claimedIds.length
      ) {
        return getOverlayStripBadge(
          ConstantMapping[clientName].BADGE_REWARD_CLAIMED
        );
      } else if (
        offer.claimConditionsFailed &&
        offer.claimConditionsFailed.length
      ) {
        return getOverlayStripBadge(
          ConstantMapping[clientName].BADGE_UNAVAILABLE
        );
      } else if (
        offer.claimLimitDetails.notEnoughCodes ||
        offer.claimLimitDetails.totalPerPeriod ||
        offer.claimLimitDetails.total
      ) {
        return getOverlayStripBadge(ConstantMapping[clientName].BADGE_NO_CODES);
      }
    }
    // Gray badges Ends

    if (offer.teaser && offer.offerType !== OfferType.INSTANT_WIN) {
      return getBadgeDetailsFromConfig(offer.teaser);
    }
  } else if (
    (offer.offerType === OfferType.INSTANT_WIN ||
      offer.offerType === OfferType.LUCKY_DIP) &&
    isOfferCodeValid(offer, claimedId) &&
    (showEmailPopup === "SHOW_POPUP" || showEmailPopup === "DETAILS_SUBMITTED")
  ) {
    return getOverlayStripBadge(
      ConstantMapping[clientName].LD_CONGRATS_BADGE,
      false,
      "",
      "LUCKY_DIP"
    );
  } else if (
    offer.offerType === OfferType.INSTANT_WIN &&
    isOfferCodeValid(offer, claimedId) &&
    offer?.instantWinResult === "win"
  ) {
    return getOverlayStripBadge(
      ConstantMapping[clientName].LD_CONGRATS_BADGE,
      false,
      "",
      "LUCKY_DIP"
    );
  } else if (
    (offer.offerType === OfferType.INSTANT_WIN ||
      offer.offerType === OfferType.LUCKY_DIP) &&
    isOfferCodeValid(offer, claimedId) &&
    showEmailPopup === "EXPIRED"
  ) {
    return getOverlayStripBadge(
      ConstantMapping[clientName].LD_CONGRATS_BADGE,
      false,
      "",
      "LUCKY_DIP"
    );
  } else if (
    (offer.offerType === OfferType.INSTANT_WIN ||
      offer.offerType === OfferType.LUCKY_DIP) &&
    isOfferCodeValid(offer, claimedId) &&
    showEmailPopup === "EXPIRED" &&
    emailCapture
  ) {
    return getOverlayStripBadge("Sorry you've missed out", false, "");
  } else if (
    offer.offerType === OfferType.INSTANT_WIN &&
    isOfferCodeValid(offer, claimedId) &&
    offer?.instantWinResult === "lose"
  ) {
    return getOverlayStripBadge("Better luck next time!", false, "");
  } else if (
    offer.offerType === OfferType.LUCKY_DIP &&
    isOfferCodeValid(offer, claimedId)
  ) {
    return getOverlayStripBadge(
      ConstantMapping[clientName].LD_CONGRATS_BADGE,
      false,
      "",
      "LUCKY_DIP"
    );
  } else if (
    offer.teaser &&
    offer.teaser === ConstantMapping[clientName].LOZENGE_MAGIC_MOMENTS &&
    offer.offerType !==
    (OfferType.INSTANT_WIN || OfferType.PRIZEDRAW || OfferType.ARTICLE)
  ) {
    return getBadgeDetailsFromConfig(offer.teaser);
  } else if (offer.offerType === OfferType.GOODWILLREWARD && isLocked) {
    return getOverlayStripBadge(
      ConstantMapping[clientName].BADGE_REWARD_CLAIMED
    );
  }
  return undefined;
};

export const getBadgeDetailsFromConfig = (key: string) => {
  let teaserConfig = AppConfig.getInsatnce(key)?.props;
  let clientName = getClientName();
  if (teaserConfig && teaserConfig.value && teaserConfig.value.length) {
    let style: CSSProperties = {
      boxShadow: "none"
    };
    let customBadgeConfig = {};
    let bText: string = "";
    teaserConfig.value.forEach((configuration: AppSectionConfig) => {
      if (configuration.name === "text") {
        bText = configuration.value;
      } else if (configuration.name === "color") {
        style.color = configuration.value;
      } else if (configuration.name === "backgroundColor") {
        style.backgroundColor = configuration.value;
      } else if (
        configuration.name === "custom" &&
        configuration.value === "true" &&
        key === ConstantMapping[clientName].LOZENGE_MAGIC_MOMENTS
      ) {
        customBadgeConfig = {
          showCustomBadgeIcon: true,
          customBadgeIcon: customBadgeIconImage
        };
      }
    });

    const badgeDetails: IBadgeProps = {
      badgeType: BadgeType.DEFAULT.toString(),
      badgeStyle: style,
      badgeText: bText,
      ...customBadgeConfig
    };
    return badgeDetails;
  }
  return undefined;
};

export const computeActions = (
  offer: Offer,
  claimedId?: number,
  location?: any
) => {
  let isValidCode = isOfferCodeValid(offer, claimedId);
  let isExpiredCode = isOfferCodeExpired(offer, claimedId);
  let isReclaimable = isOfferReclaimable(offer, claimedId);
  let isShared = isOfferShared(offer, claimedId);
  let isLocked = isOfferLocked(offer) || isOfferComingSoon(offer.startDateTime);
  let isOfferEatLocal =
    offer?.offerType === OfferType.BANNER &&
    (offer?.addType === "Eat Local" || "Coffee Club");
  let isFromMyCode =
    location?.state?.isFromMyCode || location?.pathname === "/myRewards";

  if (offer.offerType === OfferType.PRIZEDRAW) {
    if (isValidCode) {
      return Actions.PRIZE_DRAW_ENTERED;
    } else if (isExpiredCode && isReclaimable) {
      return Actions.ENTER_PRIZE_DRAW;
    } else if (isLocked) {
      return Actions.LOCKED;
    } else {
      return Actions.PRIZE_DRAW;
    }
  } else if (isOfferEatLocal && isExpiredCode) {
    return Actions.EAT_LOCAL_RENEW_MEMBERSHIP;
  } else if (isOfferEatLocal && !isExpiredCode) {
    return Actions.VIEW_CARD;
  } else if (offer.offerType === OfferType.VISIT_TO_WIN) {
    if (isValidCode) {
      return Actions.VISIT_TO_WIN_ENTERED;
    } else if (isExpiredCode && isReclaimable) {
      return Actions.ENTER_VISIT_TO_WIN;
    } else if (isLocked) {
      return Actions.LOCKED;
    } else {
      return Actions.VISIT_TO_WIN;
    }
  } else if (offer.offerType === OfferType.SPIN_TO_WIN && !isFromMyCode) {
    if (isReclaimable) {
      return Actions.SPIN_TO_WIN;
    } else if (isLocked) {
      return Actions.LOCKED;
    }
  } else if (isValidCode && isShared) {
    return Actions.SHARED;
  } else if (isExpiredCode && isReclaimable) {
    return Actions.CLAIM_AGAIN;
  } else if (offer.instantWinResult === "lose") {
    return Actions.INSTANT_WIN_LOOSE;
  } else if (offer.instantWinResult === "win") {
    return "instant-win";
  } else if (isValidCode && !isExpiredCode) {
    return Actions.USE;
  } else if (isLocked) {
    return Actions.LOCKED;
  } else if (isExpiredCode && !isReclaimable && isValidCode) {
    return Actions.NOP;
  } else if (isExpiredCode && !isReclaimable) {
    return Actions.UNAVAILABLE;
  } else if (isExpiredCode && offer.offerType === OfferType.GOODWILLREWARD) {
    return Actions.UNAVAILABLE;
  }

  return Actions.CLAIM;
};

export const checkIfOfferIsClickable = (offer: Offer) => {
  const isComingSoon = isOfferComingSoon(offer.startDateTime);
  if (
    !(offer.claimedIds && offer.claimedIds.length) &&
    !offer.claimLimitDetails.userPerPeriod &&
    !offer.claimLimitDetails.user &&
    (offer.claimLimitDetails.total ||
      offer.claimLimitDetails.totalPerPeriod ||
      (offer.claimConditionsFailed && offer.claimConditionsFailed.length) ||
      offer.claimLimitDetails.notEnoughCodes ||
      isComingSoon)
  ) {
    return false;
  }
  return true;
};

export const updateClaimedOffersFromApi = async (
  offers: IOffers,
  offer: Offer,
  claimedOffers?: any,
  offerClaimed?: any
) => {
  try {
    const response = await getClaimedOffers();
    const newOffers = Object.assign({}, offers);

    if (!response || !response.data || !response.data.claimedOffers) {
      throw "Response not valid!";
    }
    let updatedClaimedOffers = response.data.claimedOffers.offers;
    let updateAttributeClaimedOffers =
      response.data.claimedOffers.claimedOffers;
    if (updatedClaimedOffers && updatedClaimedOffers.length) {
      let uOffers = updatedClaimedOffers.filter((updatedOffer: any) => {
        return updatedOffer.id === offer.offerId;
      });
      let updatedOffer = uOffers?.length && uOffers[0];
      if (updatedOffer) {
        let newOfferData: Offer = JSON.parse(JSON.stringify(offer));
        newOfferData.prizeEntries = updatedOffer.prizeEntries;
        newOfferData.claimConditionsFailed = updatedOffer.claimConditionsFailed;
        newOfferData.claimLimitDetails = {
          notEnoughCodes: updatedOffer.claimLimitsReached.notEnoughCodes,
          user: updatedOffer.claimLimitsReached.user,
          userPerPeriod: updatedOffer.claimLimitsReached.userPerPeriod,
          total: updatedOffer.claimLimitsReached.total,
          totalPerPeriod: updatedOffer.claimLimitsReached.totalPerPeriod
        };
        newOffers[offer.offerId] = newOfferData;
        new Offers({
          id: "",
          ...newOffers
        }).$save();
      }

      if (
        claimedOffers &&
        updateAttributeClaimedOffers.length &&
        offerClaimed
      ) {
        const newClaimedOffers = Object.assign({}, claimedOffers);

        let attributeOffer = updateAttributeClaimedOffers.filter(
          (emailOffer: any) => {
            return offerClaimed.offerId === emailOffer.offerId;
          }
        );
        let claimOffer = attributeOffer?.length && attributeOffer[0];
        newClaimedOffers[claimOffer.id].attributes = claimOffer?.attributes;
        new ClaimedOffers({
          id: "",
          ...newClaimedOffers
        }).$save();
      }
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const retireveBannerDetails = (obj, matchKey) => {
  let ids = Object.keys(obj);
  for (let i = 0; i < ids.length; i++) {
    if (obj[i].key === matchKey) {
      return obj[i].value;
    }
  }
};

export const checkConvergedClaim = offer => {
  const clientName = getClientName();
  const { isConvergedOffer, offerType, additionalDetails } = offer;
  if (isConvergedOffer && offerType === OfferType.REWARD) {
    if (
      retireveBannerDetails(additionalDetails, "allowConvergedClaim") ===
      "false"
    ) {
      return getImageDetails(
        ImagesMapping[clientName].convergedClaimImage,
        "converged Icon"
      );
    }
  }
};

export const gamificatioOffer = (offer, claimedOffer) => {
  let result: any = getValueFromHashMapArray(
    claimedOffer?.attributes,
    "InstantWin"
  );
  let gamificationShowEmailPopup = getValueFromHashMapArray(
    claimedOffer?.attributes,
    "gamificationStatus"
  );
  let emailCapture = offer?.redemptionDetails?.[0]?.captureEmail || false;

  if (
    (result === "win" && offer?.offerType === OfferType.INSTANT_WIN) ||
    offer?.offerType === OfferType.LUCKY_DIP ||
    offer?.offerType === OfferType.SPIN_TO_WIN
  ) {
    if (
      gamificationShowEmailPopup === "SHOW_POPUP" ||
      gamificationShowEmailPopup === "DETAILS_SUBMITTED" ||
      gamificationShowEmailPopup === "EXPIRED"
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export const getInstantWinImageConfig = (data, offerId, key) => {
  const configKeys = {
    iw_winner_screen: "iw_winner_screen",
    iw_loser_screen: "iw_loser_screen",
    iw_play_animation: "iw_play_animation",
    offerIds: "offerIds",
    offerIdValuePair: "offerIdValuePair",
    intro_text: "intro_text",
    winner_text: "winner_text",
    loser_text: "loser_text"
  };
  let updatedDataKeyValue = "";
  let creatives = data?.CMS_iw_creativesConfigurations;
  let creativesList = Object.keys(creatives);

  creativesList?.forEach(configuration => {
    let creativeConfig = creatives[configuration];
    let ids: any[] = [];
    let tempKeyValue = "";
    for (const config of creativeConfig) {
      let configVal = configKeys[config.name];
      if (configVal === "offerIds") {
        ids = config.value.split(",");
      }
      if (configVal === key) {
        tempKeyValue = config.value;
      }
    }
    if (ids.includes(offerId)) {
      updatedDataKeyValue = tempKeyValue;
      return updatedDataKeyValue;
    }
  });

  return updatedDataKeyValue;
};

export const loadStandardCreativeImage = key => {
  let standardCreativeConfig =
    AppConfig.getInsatnce("standard-creative")?.props;
  let data = standardCreativeConfig?.value.find(item => {
    return item.name === key;
  });
  return data?.value ? data?.value : "";
};
