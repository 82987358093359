import React, { Fragment, useEffect, useState } from "react";
import { useTheme, Typography } from "@material-ui/core";
import { Offer } from "Models/ReduxModels/OffersModel";
import {
  isOfferCodeExpired,
  computeActions,
  isOfferShared,
  isOfferCodeValid
} from "Utils/offerUtils";
import { Actions } from "Models/Interfaces/Actions";
import { OfferType } from "Models/Interfaces/OfferType";
import { getClientName, getValueFromHashMapArray } from "Utils/GeneralUtils";
import { useLocation } from "react-router-dom";
import { ConstantMapping } from "Configurations/ConstantsMapping";

interface IProps {
  offer: Offer | undefined;
  mode: "Grid" | "Voucher" | "Carousel" | "Details";
  claimedId?: number;
  claimedOffer?: any;
}
const OfferActions: React.FC<IProps> = (props: IProps) => {
  const { offer, mode, claimedId, claimedOffer } = props;
  const [action, setAction] = useState("");
  const theme = useTheme();
  const location = useLocation();
  useEffect(() => {
    let result: any = getValueFromHashMapArray(
      claimedOffer?.attributes,
      "InstantWin"
    );
    let gamificationShowEmailPopup = getValueFromHashMapArray(
      claimedOffer?.attributes,
      "gamificationStatus"
    );
    // let isValidCode = offer ? isOfferCodeValid(offer, claimedId) : false;
    let emailCapture =
      offer?.offerType === OfferType.SPIN_TO_WIN ||
        offer?.offerType === OfferType.LUCKY_DIP
        ? false
        : offer?.redemptionDetails?.[0]?.captureEmail || false;
    if (
      gamificationShowEmailPopup === "SHOW_POPUP" ||
      gamificationShowEmailPopup === "DETAILS_SUBMITTED" ||
      gamificationShowEmailPopup === "EXPIRED"
    ) {
      emailCapture = true;
    }

    if (
      ((result === "win" && offer?.offerType === OfferType.INSTANT_WIN) ||
        offer?.offerType === OfferType.LUCKY_DIP ||
        offer?.offerType === OfferType.SPIN_TO_WIN) &&
      emailCapture === true
    ) {
      if (
        gamificationShowEmailPopup === "SHOW_POPUP" &&
        offer?.offerType !== OfferType.SPIN_TO_WIN &&
        mode !== "Grid"
      ) {
        setAction(Actions.GAMIFICATION_CLAIM_PRIZE);
      }
    } else if (claimedOffer?.prizeType === ConstantMapping[getClientName()].GRAND_PRIZE) {
      setAction(Actions.GRAND_PRIZE);
    } else {
      if (offer) {
        setAction(computeActions(offer, claimedId, location));
      }
    }
  }, [offer]);

  const renderAction = (color: any, label: string) => {
    return (
      <Typography
        style={{
          color: color,
          marginRight: theme.spacing(2.25)
        }}
        variant="h6"
      >
        {label}
      </Typography>
    );
  };

  const renderActionSmall = (color: any, label: string) => {
    return (
      <Typography
        style={{
          color: color
        }}
        variant="subtitle2"
      >
        {label}
      </Typography>
    );
  };

  const renderBaseAction = (isSmall: boolean) => {
    switch (action) {
      case "gamification-claim-prize":
        return isSmall
          ? renderActionSmall("#00B0CA", "Claim prize")
          : renderAction("#00B0CA", "Claim prize");
      case "renew-membership":
        return isSmall
          ? renderActionSmall("#000", "Renew membership")
          : renderAction("#000", "Renew membership");
      case "instant-win":
        return isSmall
          ? renderActionSmall("#00B0CA", "Show prize")
          : renderAction("#00B0CA", "Show prize");
      case "grand-prize":
        return isSmall
          ? renderActionSmall("#00B0CA", "Show Prize")
          : renderAction("#00B0CA", "Show Prize");
      case "use":
        return isSmall
          ? renderActionSmall("#00B0CA", "Show Code")
          : renderAction("#00B0CA", "Show Code");
      case Actions.PRIZE_DRAW_ENTERED:
      case Actions.VISIT_TO_WIN_ENTERED:
        return isSmall
          ? renderActionSmall("#9C2AA0", "Entered")
          : renderAction("#9C2AA0", "Entered");
      case Actions.SHARED:
        return isSmall
          ? renderActionSmall("#e80000", "Gifted")
          : renderAction("#e80000", "Gifted");
      case Actions.VIEW_CARD:
        return isSmall
          ? renderActionSmall("#00B0CA", "View Card")
          : renderAction("#00B0CA", "View Card");
      case Actions.LUCKY_DRAW_WIN:
      case Actions.SPIN_TO_WIN:
        return isSmall
          ? renderActionSmall("#00B0CA", "Show code")
          : renderAction("#00B0CA", "Show code");
    }

    return <Fragment></Fragment>;
  };

  const renderGridAction = () => {
    return renderBaseAction(true);
  };

  const renderCarouselAction = () => {
    return renderBaseAction(false);
  };

  const renderVoucherAction = () => {
    if (
      offer &&
      !isOfferCodeExpired(offer, claimedId) &&
      isOfferShared(offer, claimedId)
    ) {
      return renderAction("#777777", "Help");
    }
    return renderBaseAction(false);
  };

  // const renderDetailsAction = () => {};

  const render = () => {
    if (!offer) {
      return <Fragment></Fragment>;
    }
    switch (mode) {
      case "Carousel":
        return renderCarouselAction();
      case "Grid":
        return renderGridAction();
      case "Voucher":
        return renderVoucherAction();
    }
    return <Fragment></Fragment>;
  };

  return <Fragment>{render()}</Fragment>;
};

export default OfferActions;
